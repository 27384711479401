import ReactS3 from "react-s3";

// const awsAccessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
// const awsSecretAccessKey = process.env.REACT_APP_SECRET_ACCESS_KEY;
// const awsRegion = process.env.REACT_APP_AWS_REGION;
// const s3BucketName = process.env.REACT_APP_S3_BUCKET_NAME;

// data should have the following input.
// data = { 
//     key: '', // key to be deleted
//     s3subdir: '' // subdirectory I need to delete
// }

export const deleteS3File = async (data) => {
  // const config = {
  //   bucketName: s3BucketName,
  //   dirName: data.s3subdir,
  //   region: awsRegion,
  //   accessKeyId: awsAccessKeyId,
  //   secretAccessKey: awsSecretAccessKey,
  // };
  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    dirName: data.s3subdir,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };
  // console.log("---config:", config);
  // console.log('---data:', data);
  try {
    let delfilename = data.key.split("/").pop();
    await ReactS3.deleteFile(delfilename, config);

    // console.log('----ret:', ret);

    return { status: 'success', Msg: ''}
  } catch (err) {
    console.log('deleteS3File err:', err.message);
    return { status: 'error', Msg: err.message}
  }
};

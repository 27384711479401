// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.gl-filter-box {
    /* display: block; */
    position: absolute;
    text-align: center;
    width: 60%;
    height: 390px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #f0f8fa;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 30;
    font-size: 1em;
    font-weight: 600;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 25px;
    margin-left: 0%;
  }

  .list-gl-metadata {
    font-size: 1em;
    margin-top: 10px;
    display: block;
    position: absolute;
    z-index: 50;
    width: 60%;
  }

  .gl-filter-metadata-list-pos {
    margin-top: 10px;
    margin-left: 20%;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/reports/gl/GLSearchFilter.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,8EAA8E;IAC9E,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB","sourcesContent":["\n.gl-filter-box {\n    /* display: block; */\n    position: absolute;\n    text-align: center;\n    width: 60%;\n    height: 390px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #f0f8fa;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    z-index: 30;\n    font-size: 1em;\n    font-weight: 600;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 10%;\n    margin-bottom: 25px;\n    margin-left: 0%;\n  }\n\n  .list-gl-metadata {\n    font-size: 1em;\n    margin-top: 10px;\n    display: block;\n    position: absolute;\n    z-index: 50;\n    width: 60%;\n  }\n\n  .gl-filter-metadata-list-pos {\n    margin-top: 10px;\n    margin-left: 20%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

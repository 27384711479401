// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixedsize_catalog_paginated {
  height: 500px;
  width: 100%;
  background-color: rgba(211, 221, 227, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

.btn-catalog-open {
  width: 44px;
  height: 26px;
  background-color: #66491b;
  border: none;
  color: white;
  font-size: 0.85em;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 4px;
  margin-left: 25%;
  margin-right: -25%;
}

.btn-clrnxt-catalog-pagi {
  width: 32px;
  height: 28px;
  background-color: #8c4c3f;
  border: none;
  color: white;
  font-size: 0.9em;
  font-size: 550;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-top: 2px;
  margin-right: 10px;
}

.cat-pagi-no-rows {
  font-size: 1em;
  font-weight: 650;
  color: red;
}

.row-cat-mgmt-color-dr {
  background-color: #dfe3eb;
  height: 40px;
}

.row-cat-mgmt-color-lt {
  background-color: #f7faff;
  height: 40px;
}

.cat-item-but-pos {
  margin-top: 4px;
  margin-bottom: -4px;
  margin-left: 20px;
  margin-right: -20px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/catalog/parts/CatalogPaginated.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,0CAA0C;EAC1C,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,iCAAiC;EACjC,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".fixedsize_catalog_paginated {\n  height: 500px;\n  width: 100%;\n  background-color: rgba(211, 221, 227, 0.5);\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.btn-catalog-open {\n  width: 44px;\n  height: 26px;\n  background-color: #66491b;\n  border: none;\n  color: white;\n  font-size: 0.85em;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-top: 2px;\n  margin-bottom: 4px;\n  margin-left: 25%;\n  margin-right: -25%;\n}\n\n.btn-clrnxt-catalog-pagi {\n  width: 32px;\n  height: 28px;\n  background-color: #8c4c3f;\n  border: none;\n  color: white;\n  font-size: 0.9em;\n  font-size: 550;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-top: 2px;\n  margin-right: 10px;\n}\n\n.cat-pagi-no-rows {\n  font-size: 1em;\n  font-weight: 650;\n  color: red;\n}\n\n.row-cat-mgmt-color-dr {\n  background-color: #dfe3eb;\n  height: 40px;\n}\n\n.row-cat-mgmt-color-lt {\n  background-color: #f7faff;\n  height: 40px;\n}\n\n.cat-item-but-pos {\n  margin-top: 4px;\n  margin-bottom: -4px;\n  margin-left: 20px;\n  margin-right: -20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ship-filter-box {
    display: block;
    /* margin: auto; */
    text-align: center;
    width: 60%;
    height: 330px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #e4e8f0;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    /* position: absolute; */
    z-index: 15;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 10%; */
    margin-top: 5%;
    margin-bottom: 25px;
  }

  .ship-filter-header {
      font-size: 1em;
      font-weight: 650;
      margin-bottom: 15px;;
  }

  .ship-cust-name {
      width: 90%;
  }

  .ship-custname-pos {
      margin-top: 20px;
  }

  .ship-status-row-pos {
      margin-top: 20px;
  }

  .ship-button-pos {
      margin-top: 15px;
  }

  .ship-name-msg{
      font-size: .8em;
      color: grey;
  }

  .ship-filter-invoice {
      width: 30%;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/delivery/ship/ShipFilter.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,8EAA8E;IAC9E,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,cAAc;IACd,mBAAmB;EACrB;;EAEA;MACI,cAAc;MACd,gBAAgB;MAChB,mBAAmB;EACvB;;EAEA;MACI,UAAU;EACd;;EAEA;MACI,gBAAgB;EACpB;;EAEA;MACI,gBAAgB;EACpB;;EAEA;MACI,gBAAgB;EACpB;;EAEA;MACI,eAAe;MACf,WAAW;EACf;;EAEA;MACI,UAAU;EACd","sourcesContent":[".ship-filter-box {\n    display: block;\n    /* margin: auto; */\n    text-align: center;\n    width: 60%;\n    height: 330px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #e4e8f0;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    /* position: absolute; */\n    z-index: 15;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    /* margin-left: 10%; */\n    margin-top: 5%;\n    margin-bottom: 25px;\n  }\n\n  .ship-filter-header {\n      font-size: 1em;\n      font-weight: 650;\n      margin-bottom: 15px;;\n  }\n\n  .ship-cust-name {\n      width: 90%;\n  }\n\n  .ship-custname-pos {\n      margin-top: 20px;\n  }\n\n  .ship-status-row-pos {\n      margin-top: 20px;\n  }\n\n  .ship-button-pos {\n      margin-top: 15px;\n  }\n\n  .ship-name-msg{\n      font-size: .8em;\n      color: grey;\n  }\n\n  .ship-filter-invoice {\n      width: 30%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-coop-qna-box {
    display: block;
  
    text-align: center;
    width: 100%;
    height: 600px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #e4e8f0;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
   
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 25px;
  }

  .coop-price-agr{
    margin-top: 10px;
    margin-bottom: -10px;
    font-weight: 600;
  }

  .coop-wr-agg-meter {
    width: 15%;
  }

  .coop-disc-col {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .coop-wr-agg-disc {
    width: 10%;
  }

  .coop-disc-paydelay {
    margin-top: -10px;
  }

  .coop-wr-agg-fine {
    width: 8%;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/administration/coopStore/WriteAgreementClauses.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,8EAA8E;IAC9E,kBAAkB;;IAElB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,SAAS;EACX","sourcesContent":[".new-coop-qna-box {\n    display: block;\n  \n    text-align: center;\n    width: 100%;\n    height: 600px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #e4e8f0;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n   \n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 2%;\n    margin-bottom: 25px;\n  }\n\n  .coop-price-agr{\n    margin-top: 10px;\n    margin-bottom: -10px;\n    font-weight: 600;\n  }\n\n  .coop-wr-agg-meter {\n    width: 15%;\n  }\n\n  .coop-disc-col {\n    margin-top: 10px;\n    margin-bottom: 20px;\n    font-weight: 600;\n  }\n\n  .coop-wr-agg-disc {\n    width: 10%;\n  }\n\n  .coop-disc-paydelay {\n    margin-top: -10px;\n  }\n\n  .coop-wr-agg-fine {\n    width: 8%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

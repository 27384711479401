// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gl-detail-box {
  position: absolute;
  text-align: center;
  width: 60%;
  height: 390px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #f0f4fa;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 30;
  font-size: 1em;
  font-weight: 600;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  margin-bottom: 25px;
  margin-left: 2%;
}

.detail-row-pos {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/reports/gl/GLDetail.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,eAAe;AACjB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".gl-detail-box {\n  position: absolute;\n  text-align: center;\n  width: 60%;\n  height: 390px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #f0f4fa;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 30;\n  font-size: 1em;\n  font-weight: 600;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 10%;\n  margin-bottom: 25px;\n  margin-left: 2%;\n}\n\n.detail-row-pos {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

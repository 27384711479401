// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-display-frame-box {
  width: 80%;
  height: 620px;
  border: 2px solid gray;
  padding: 5px;
  background-color: rgba(237, 250, 241, 0.9);
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 1%;
  /* position: absolute;
  z-index: 10; */
  margin-left: 10%;
}

.btn-return-to-bmail {
  width: 90px;
  height: 30px;
  background-color: #8f420e;
  border: none;
  color: white;
  font-size: .9em;
  font-weight: 600;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-left: 15px;
  /* margin-top: 100px; */
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/converse/present/ShowEventPurchase.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,0CAA0C;EAC1C,8EAA8E;EAC9E,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd;gBACc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iCAAiC;EACjC,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".event-display-frame-box {\n  width: 80%;\n  height: 620px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: rgba(237, 250, 241, 0.9);\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  margin-bottom: 5px;\n  margin-top: 1%;\n  /* position: absolute;\n  z-index: 10; */\n  margin-left: 10%;\n}\n\n.btn-return-to-bmail {\n  width: 90px;\n  height: 30px;\n  background-color: #8f420e;\n  border: none;\n  color: white;\n  font-size: .9em;\n  font-weight: 600;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-left: 15px;\n  /* margin-top: 100px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

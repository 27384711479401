// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-box-cc-signup {
  display: block;
  /* margin: auto; */
  text-align: center;
  width: 350px;
  height: 200px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #07194a;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  /* position: absolute; */
  z-index: 10;
  /* overflow-y: auto;
    overflow-x: hidden; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 25px;
  border-radius: 20px;
}

.card-strip-cc-platform {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1em;
  background-color: aliceblue;
  margin-bottom: 25px;
}



.cc_platform_msg {
  font-size: 1em;
  color: white;
  font-weight: 650;
  margin-bottom: 20px;
  margin-top: 15px;
}

.cc-thanks-platform {
  margin-top: 30px;
  color: white;
}

.spin_platform_position {
  margin-top: 20px;
  margin-left: 40%;
}

.pay-cc-platform-error {
  font-size: .8em;
  color: red;
  margin-top: 10px;
}

@media only screen and (max-device-width: 480px) {
  .cc_platform_msg {
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/account/finance/communities/PayForCCPlatform.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,wBAAwB;EACxB,WAAW;EACX;yBACuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,2BAA2B;EAC3B,mBAAmB;AACrB;;;;AAIA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":[".card-box-cc-signup {\n  display: block;\n  /* margin: auto; */\n  text-align: center;\n  width: 350px;\n  height: 200px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #07194a;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  /* position: absolute; */\n  z-index: 10;\n  /* overflow-y: auto;\n    overflow-x: hidden; */\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 2%;\n  margin-bottom: 25px;\n  border-radius: 20px;\n}\n\n.card-strip-cc-platform {\n  padding-top: 5px;\n  padding-bottom: 5px;\n  font-size: 1em;\n  background-color: aliceblue;\n  margin-bottom: 25px;\n}\n\n\n\n.cc_platform_msg {\n  font-size: 1em;\n  color: white;\n  font-weight: 650;\n  margin-bottom: 20px;\n  margin-top: 15px;\n}\n\n.cc-thanks-platform {\n  margin-top: 30px;\n  color: white;\n}\n\n.spin_platform_position {\n  margin-top: 20px;\n  margin-left: 40%;\n}\n\n.pay-cc-platform-error {\n  font-size: .8em;\n  color: red;\n  margin-top: 10px;\n}\n\n@media only screen and (max-device-width: 480px) {\n  .cc_platform_msg {\n    font-size: 0.9em;\n    font-weight: 600;\n    margin-bottom: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-err-msg-pos {
  margin-top: 160px;
}

.frame-write-head-pos {
  margin-top: 220px;
}

.btn_exit_del_subctive {
  width: 40px;
  height: 30px;
  background-color: #ba8e82;
  border: 1px solid #9c3f11;
  color: #ffffff;
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  margin-right: 10px;
}

.btn_exit_del_30_subctive {
  width: 30px;
  height: 30px;
  background-color: #ba8e82;
  border: 1px solid #9c3f11;
  color: #ffffff;
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  margin-right: 10px;
}

.testpos-aa{
  margin-top: 155px;
  margin-bottom: -155px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/templates/utils/FrameCompose.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,0CAA0C;EAC1C,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,0CAA0C;EAC1C,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB","sourcesContent":[".frame-err-msg-pos {\n  margin-top: 160px;\n}\n\n.frame-write-head-pos {\n  margin-top: 220px;\n}\n\n.btn_exit_del_subctive {\n  width: 40px;\n  height: 30px;\n  background-color: #ba8e82;\n  border: 1px solid #9c3f11;\n  color: #ffffff;\n  font-size: 0.9em;\n  font-weight: 600;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.8);\n  border-radius: 5px;\n  margin-right: 10px;\n}\n\n.btn_exit_del_30_subctive {\n  width: 30px;\n  height: 30px;\n  background-color: #ba8e82;\n  border: 1px solid #9c3f11;\n  color: #ffffff;\n  font-size: 0.9em;\n  font-weight: 600;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.8);\n  border-radius: 5px;\n  margin-right: 10px;\n}\n\n.testpos-aa{\n  margin-top: 155px;\n  margin-bottom: -155px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

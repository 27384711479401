// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .btn_inv-edit-addr-exit {
  width: 40px;
  height: 30px;
  background-color: #753c07;
  border: none;
  color: white;
  font-size: 1em;
  font-weight: 650;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-left: 5px;
}

.btn_inv-edit-addr-done {
  width: 70px;
  height: 32px;
  background-color: green;
  border: none;
  color: white;
  font-size: 1em;
  font-weight: 650;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-left: 5px;
} */

.inv-edit-addr-head {
  font-size: 1.1em;
  font-weight: 650;
  margin-top: 10px;
  margin-bottom: 70px;
}

.inv-edit-addr-street1 {
  width: 80%;
}

.inv-edit-addr-city1 {
  width: 50%;
}

.inv-edit-addr-state1 {
  width: 15%;
}

.inv-edit-addr-postalCode1 {
  width: 25%;
}

.inv-edit-addr-lbl {
  font-size: 1em;
  font-weight: 650;
}

.inv-edit-addr-row-pos {
  margin-top: 10px;
}

.ship-decision-row {
  margin-top: 10px;
}

.ship-addr-spec {
  font-size: 1em;
  font-weight: 650;
}

.man-inv-ship-addr-msg-err {
  font-size: 1em;
  font-weight: 650;
  color: red;
  margin-top: 15px;
}

.man-inv-ship-addr-msg {
  font-size: 1em;
  font-weight: 650;
  margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/receivable/newInvoice/utils/EditTargetAddress.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;GAwBG;;AAEH;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["/* .btn_inv-edit-addr-exit {\n  width: 40px;\n  height: 30px;\n  background-color: #753c07;\n  border: none;\n  color: white;\n  font-size: 1em;\n  font-weight: 650;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-left: 5px;\n}\n\n.btn_inv-edit-addr-done {\n  width: 70px;\n  height: 32px;\n  background-color: green;\n  border: none;\n  color: white;\n  font-size: 1em;\n  font-weight: 650;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-left: 5px;\n} */\n\n.inv-edit-addr-head {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-top: 10px;\n  margin-bottom: 70px;\n}\n\n.inv-edit-addr-street1 {\n  width: 80%;\n}\n\n.inv-edit-addr-city1 {\n  width: 50%;\n}\n\n.inv-edit-addr-state1 {\n  width: 15%;\n}\n\n.inv-edit-addr-postalCode1 {\n  width: 25%;\n}\n\n.inv-edit-addr-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.inv-edit-addr-row-pos {\n  margin-top: 10px;\n}\n\n.ship-decision-row {\n  margin-top: 10px;\n}\n\n.ship-addr-spec {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.man-inv-ship-addr-msg-err {\n  font-size: 1em;\n  font-weight: 650;\n  color: red;\n  margin-top: 15px;\n}\n\n.man-inv-ship-addr-msg {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

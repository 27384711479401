// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-compose-dg-ops {
  width: 120px;
  height: 30px;
  background-color: #247485;
  border: none;
  color: white;
  font-size: 0.9em;
  font-weight: 550;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 25px;
  border: solid 1px black;
}

.da-upload-header {
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 5px;
}

.da-upload-sub-header {
  font-size: 1em;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 25px;
  color: #2c5373;
}


.drb-da-card {
    width: 40%;
    height: auto;
    margin-top: 15px;
    background-color: white;
    border-radius: 5px;
    border: solid 1px gray;
    margin-left: 30%;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.brd-image-msg {
    font-size: .85;
    font-weight: 550;
    color:rgba(0, 0, 0, 0.5);
    margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/daloads/UploadCompositionDA.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,iCAAiC;EACjC,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;;AAGA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,8EAA8E;AAClF;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,wBAAwB;IACxB,eAAe;AACnB","sourcesContent":[".btn-compose-dg-ops {\n  width: 120px;\n  height: 30px;\n  background-color: #247485;\n  border: none;\n  color: white;\n  font-size: 0.9em;\n  font-weight: 550;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-left: 15px;\n  margin-top: 25px;\n  border: solid 1px black;\n}\n\n.da-upload-header {\n  font-size: 1.1em;\n  font-weight: 700;\n  margin-top: 10px;\n  margin-bottom: 5px;\n}\n\n.da-upload-sub-header {\n  font-size: 1em;\n  font-weight: 600;\n  margin-top: 5px;\n  margin-bottom: 25px;\n  color: #2c5373;\n}\n\n\n.drb-da-card {\n    width: 40%;\n    height: auto;\n    margin-top: 15px;\n    background-color: white;\n    border-radius: 5px;\n    border: solid 1px gray;\n    margin-left: 30%;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n}\n\n.brd-image-msg {\n    font-size: .85;\n    font-weight: 550;\n    color:rgba(0, 0, 0, 0.5);\n    margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ret-can-inperson-item-box {
    width: 100%;
    height: 100px;
    max-height: 620px;
    background-color: #f5f2eb;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid gray;
    margin-top: 15px;
  }

.name-panel-row-inperson {
    margin-top: -15px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/delivery/returns/ConductCashRefund.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;EAClB;;AAEF;IACI,iBAAiB;AACrB","sourcesContent":[".ret-can-inperson-item-box {\n    width: 100%;\n    height: 100px;\n    max-height: 620px;\n    background-color: #f5f2eb;\n    overflow-x: hidden;\n    overflow-y: auto;\n    border: 1px solid gray;\n    margin-top: 15px;\n  }\n\n.name-panel-row-inperson {\n    margin-top: -15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

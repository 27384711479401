// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsletter-review {
    width: 485px;
    height: 560px;
    border: 2px solid gray;
    padding: 5px;
    margin-top: 5px;
    /* background-color: black; */
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: -45px;
    position: absolute;
    z-index: 50;
    margin-left: 25%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .footer-box {
    width: 400px;
    height: auto;
    background-color: lightblue;
    border: 1px dashed gray;
    margin-left: 3px;
    padding-top: 5px;
  }

  .letter-signoff {
    background-color: #fff;
    margin-left: 3px;
    width: 457px;
  }

  /* //   backgroundColor: this.state.introbgColor,
  //   width: "360px",
  //   marginLeft: "3px",
  //   paddingLeft: "3px",
  //   marginTop: "20px", */

  .newslett-top-panel {
    width: 360px;
    margin-left: 3px;
    padding-left: 3px;
    margin-top: 20px;
  }

  .image-panel-back {
    margin-left: 3px;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/newsLetter/review/NewsLetReview.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,6BAA6B;IAC7B,8EAA8E;IAC9E,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;EACd;;EAEA;;;;2BAIyB;;EAEzB;IACE,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".newsletter-review {\n    width: 485px;\n    height: 560px;\n    border: 2px solid gray;\n    padding: 5px;\n    margin-top: 5px;\n    /* background-color: black; */\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    margin-bottom: 5px;\n    margin-top: -45px;\n    position: absolute;\n    z-index: 50;\n    margin-left: 25%;\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n  \n  .footer-box {\n    width: 400px;\n    height: auto;\n    background-color: lightblue;\n    border: 1px dashed gray;\n    margin-left: 3px;\n    padding-top: 5px;\n  }\n\n  .letter-signoff {\n    background-color: #fff;\n    margin-left: 3px;\n    width: 457px;\n  }\n\n  /* //   backgroundColor: this.state.introbgColor,\n  //   width: \"360px\",\n  //   marginLeft: \"3px\",\n  //   paddingLeft: \"3px\",\n  //   marginTop: \"20px\", */\n\n  .newslett-top-panel {\n    width: 360px;\n    margin-left: 3px;\n    padding-left: 3px;\n    margin-top: 20px;\n  }\n\n  .image-panel-back {\n    margin-left: 3px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from "react-loading";

import "./ReceiveMoneyViaCC.css";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
 
import axios from "axios";

// const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY; 

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;

const receiveLoanInvestPay = "/routes/dashboard/receiveLoanInvestPay";

const handleInvoiceOfSales = "/routes/dashboard/handleInvoiceOfSales";
const receivePayment = "/routes/dashboard/receivePayment";
const saveUpdateCommunity = "/routes/create/saveUpdateCommunity";
const saveInviteResponse = "/routes/join/saveInviteResponse";
const postABooking = "/routes/interact/postABooking";
const handleInvoicePayment = "/routes/finance/handleInvoicePayment";
const handlePayments = "/routes/coop/handlePayments";


const CCPayoutForm = (props) => {
  // console.log("1. props:", props);
  const stripe = useStripe();
  const elements = useElements();

  // Used for display on the card
  let amount = props.chargeAmount;

  const [valueof, setValueof] = useState(true);
  const [message, setMessage] = useState(0);
  const [errmsg, setErrmsg] = useState("");
  const [spinner, setSpinner] = useState(false);
  // eslint-disable-next-line
  const [disabled, setDisabled] = useState(false);

  const [isShownPay, setIsShownPay] = useState(false);
  const [isShownCancel, setIsShownCancel] = useState(false);
  const [isShownFinito, setIsShownFinito] = useState(false);

  const handleSubmit = async (event) => {
    // console.log("2. props:", props);
    // console.log("stripeaccId props:", props.stripeaccId);
    // console.log("in handleSubmit");
    event.preventDefault();
    setMessage(1);
    setSpinner(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    setSpinner(false);

    // console.log('6. error:', error);
    // console.log('6. paymentMethod:', paymentMethod);
    // console.log('6. paymentMethod.id:', paymentMethod.id);

    // const { id } = paymentMethod;
    // let idd = props.inputData;
    // idd.StripePaymentId = id;
    // console.log('>>> idd:', idd);

    if (!error) {
      const { id } = paymentMethod;
      let idd = props.inputData;
      idd.StripePaymentId = id;
      // console.log(">>> idd:", idd);
      // console.log("paymentMethod id:", id);
      if (idd.paymentFor === "loanInvest") {
        loanInvestHandling();
      }
      if (idd.paymentFor === "invoice") {
        // console.log("pay cc ---- invoice");
        await invoiceHandling(paymentMethod);
      }
      if (idd.paymentFor === "receivePayment") {
        // console.log("pay cc ---- invoice");
        paymentHandling();
      }
      if (idd.paymentFor === "publishCommunityFee") {
        // console.log("pay cc ---- invoice");
        let inpdata = props.inputData;
        inpdata.StripePaymentId = id;
        communityFeeHandling(inpdata);
      }
      if (idd.paymentFor === "membershipFee") {
        // console.log("pay cc ---- membershipFee");
        let inpData = props.inputData;
        inpData.StripePaymentId = id;
        // console.log("inpData:", inpData);
        handleMembershipFee(inpData);
      }
      if (idd.paymentFor === "postAnEvent") {
        // console.log("pay cc ---- membershipFee");
        let inpData = props.inputData;
        inpData.StripePaymentId = id;
        // console.log("inpData:", inpData);
        handlePostingOfAnEvent(inpData);
      }
      if (idd.paymentFor === 'manualinvoice') {
        // console.log("pay cc ---- manualinvoice");
        let inpData = props.inputData;
        inpData.StripePaymentId = id;
        // console.log("inpData:", inpData);
        manualInvoicePayment(inpData);
      }
      if (idd.paymentFor === 'coopExchange') {
        // console.log("pay cc ---- coopExchange");
        let inpData = props.inputData;
        inpData.StripePaymentId = id;
        // console.log("inpData:", inpData);
        coopExchangePayment(inpData);
      }
    } else {
      console.log("createPaymentMethod error:", error);
      setMessage(0);
      setErrmsg(error.message);
    }
  };

  const loanInvestHandling = async () => {
    try {
      let url = baandaServer + receiveLoanInvestPay;
      setSpinner(true);
      const { data } = await axios.post(url, props.inputData);
      setSpinner(false);
      // console.log("data:", data);
      if (data.status === "success") {
        setValueof(false);
        // doFinito();
        // setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  const invoiceHandling = async (paymentMethod) => {
    console.log("invoiceHandling");
    try {
      let inp = props.inputData;
      // const { id } = paymentMethod;
      inp.ccData.paymentMethod = paymentMethod;
      inp.ccData.merchantStripeAccId = props.stripeaccId;

      let url = baandaServer + handleInvoiceOfSales;
      // console.log("url:", url, " props.inputData:", inp);
      setSpinner(true);
      const { data } = await axios.post(url, props.inputData);
      setSpinner(false);
      // console.log("4. data:", data);
      if (data.status === "success") {
        // console.log("We had success here ...");
        setValueof(false);
        // doFinito();
        // setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  const paymentHandling = async () => {
    // console.log("paymentHandling");
    try {
      let url = baandaServer + receivePayment;
      // console.log(
      //   "paymentHandling url:",
      //   url,
      //   " props.inputData:",
      //   props.inputData
      // );
      setSpinner(true);
      const { data } = await axios.post(url, props.inputData);
      setSpinner(false);
      // console.log("data:", data);
      if (data.status === "success") {
        setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  const manualInvoicePayment = async(input) => {
    // console.log("manualInvoicePayment");
    try {
      let url = baandaServer + handleInvoicePayment;
      setSpinner(true);
      const { data } = await axios.post(url, input);
      setSpinner(false);
      // console.log("data:", data);
      if (data.status === "success") {
        setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  }

  const coopExchangePayment = async(input) => {
    // console.log("coopExchangePayment");
    try {
      let url = baandaServer + handlePayments;
      setSpinner(true);
      const { data } = await axios.post(url, input);
      setSpinner(false);
      // console.log("data:", data);
      if (data.status === "success") {
        setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  }


  const communityFeeHandling = async (input) => {
    // console.log("communityFeeHandling ... input:");
    try {
      let url = baandaServer + saveUpdateCommunity;
      setSpinner(true);

      const { data } = await axios.post(url, input);
      // console.log("data:", data);
      if (data.status === "success") {
        setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(data.Msg);
      }
    } catch (err) {
      console.log("communityFeeHandling err:", err.message);
    }
  };

  const handleMembershipFee = async (input) => {
    // console.log('in handleMembershipFee input:', input);
    try {
      let url = baandaServer + saveInviteResponse;
      setSpinner(true);

      // const { data } = await axios.post(url, input);
      let invret = await axios.post(url, input);
      // console.log("invret:", invret);
      if (invret.data.status === "success") {
        setValueof(false);
        // doFinito();
        // setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(invret.data.Msg);
      }
    } catch (err) {
      console.log("handleMembershipFee err:", err.message);
    }
  };

  const handlePostingOfAnEvent = async (input) => {
    // console.log('handlePostingOfAnEvent input:', input);
    try {
      let url = baandaServer + postABooking;
      setSpinner(true);

      const { data } = await axios.post(url, input);
      // console.log("data:", data);
      if (data.status === "success") {
        setValueof(false);
      } else {
        setMessage(0);
        setErrmsg(data.Msg);
      }
    } catch (err) {
      console.log("handlePostingOfAnEvent err:", err.message);
    }
  };

  const doFinito = async () => {
    // console.log("in doFinito ...");
    props.handleExit("success");
  };
  const doCancel = async () => {
    props.handleExit("cancel");
  };

  // console.log(">>>> valueof:", valueof);

  let buttonPanel;
  if (message === 0) {
    buttonPanel = (
      <div>
        <button
          style={{ cursor: disabled ? "default" : "pointer" }}
          type="submit"
          className={
            isShownPay ? "btn_reg_60 rec-cc-pay-btn-pos" : "btn_reg_60 rec-cc-pay-btn-pos"
          }
          disabled={!stripe}
          onMouseEnter={() => setIsShownPay(true)}
          onMouseLeave={() => setIsShownPay(false)}
        >
          Pay
        </button>
        <div className="cc_err_msg">{errmsg}</div>
      </div>
    );
  }

  let cancelBtn = (
    <div>
      <button
        onClick={doCancel}
        style={{ cursor: disabled ? "default" : "pointer" }}
        className={
          isShownCancel
            ? "btn_reg_80"
            : "btn_reg_80"
        }
        onMouseEnter={() => setIsShownCancel(true)}
        onMouseLeave={() => setIsShownCancel(false)}
      >
        Cancel
      </button>
      <div className="community-cc-payment-description">
        {props.description}
      </div>
      <div className="text-center comm-cc-payment-wait">
        Note: Please wait. Processing may take few moments.
      </div>
    </div>
  );
  let finishBtn = (
    <div>
      <button
        onClick={doFinito}
        className={
          isShownFinito ? "btn_reg_80" : "btn_reg_80"
        }
        style={{ cursor: disabled ? "default" : "pointer" }}
        onMouseEnter={() => setIsShownFinito(true)}
        onMouseLeave={() => setIsShownFinito(false)}
      >
        Finito
      </button>
    </div>
  );

  let btnbelow;
  // console.log("1. #### valueof:", valueof);
  if (valueof) btnbelow = cancelBtn;
  else btnbelow = finishBtn;

  let uploadingSpin;
  if (spinner) {
    uploadingSpin = (
      <div>
        <ReactLoading
          type={"spokes"}
          color={"#dfe9f0"}
          height={30}
          width={30}
        />
      </div>
    );
  }

  // console.log("2. #### valueof:", valueof);
  let creditCardPanel;
  if (valueof) {
    creditCardPanel = (
      <div>
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "300px", margin: "0 auto" }}
        >
          <div className="cc-msg">Amount to pay is ${amount.toFixed(2)}</div>
          <CardElement className="some-backx" />
          {buttonPanel}
        </form>
        <div className="text-center spin_position">{uploadingSpin}</div>
      </div>
    );
  } else {
    creditCardPanel = (
      <div className="cc_thanks_for_biz">
        <h3>Thank you for the business</h3>
      </div>
    );
  }

  let cconcard = (
    <div className="card-box-cc-invoice-x">
      {creditCardPanel}
    </div>
  );


  let outputPanel;
  if (props.deviceSize === "small") {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col-1 text-center">&nbsp;</div>
          <div className="col-11">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center deliver-to-text-x">{btnbelow}</div>
        </div>
      </div>
    );
  } else {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col text-center cc-place">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center btn-below-place">{btnbelow}</div>
        </div>
      </div>
    );
  }

  return <div>{outputPanel}</div>;
};

//=====================================================================
// Calls the function, invoked from other cmomponent, to expose CC form.
const ReceiveLoanInvestviaCC = (props) => {
  console.log("ReceiveMoneyViaCC ReceiveLoanInvestviaCC ");
  // console.log('1 props:', props);
  // console.log('0. inputData:', props.inputData)
  // console.log("0. props.inputData.stripeaccId:", props.stripeaccId);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: props.stripeaccId,
  });
  let amount = parseFloat(parseFloat(props.paymentAmount).toFixed(2));

  let description = "";
  if (props.caller === "inviteresponse") {
    description =
      "Participation fee for attending " + props.inputData.eventName;
  }

  return (
    <div>
      <Elements stripe={stripePromise}>
        <CCPayoutForm
          chargeAmount={amount}
          inputData={props.inputData}
          deviceSize={props.deviceSize}
          handleExit={(retdata) => props.handleExit(retdata)}
          stripeaccId={props.stripeaccId}
          description={description}
        />
      </Elements>
    </div>
  );
};

export default ReceiveLoanInvestviaCC;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inv-pbl-header {
  font-size: 1.1em;
  font-weight: 650;
}

.inv-pbl-header-invid {
  font-size: 1em;
  font-weight: 650;
  color: blue;
}

.inv-pbl-vend-lbl {
  font-size: 1em;
  font-weight: 650;
}

.inv-pbl-vend-txt {
  font-size: 1em;
  font-weight: 600;
}

.pbl-line-list-header {
  font-size: 1em;
  font-weight: 650;
  margin-left: 20px;
  margin-right: -20px;
}

.pbl-topay-row-pos {
    margin-top: 10px;
}

.pbl-pay-panel-pos {
    margin-top: 0px;
}

.pbl-pay-note {
    width: 100%;
}

.pbl-note-pos {
    margin-top: 10px;
}

.pbl-pay-date-pos {
  margin-top: 10px;
}

.pbl-payment-msg{
  font-size: 1em;
  font-weight: 650;
}

.pbl-payment-msg-err{
  font-size: 1em;
  font-weight: 650;
  color: red;
}

`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/liability/payables/ShowPayableInvoice.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".inv-pbl-header {\n  font-size: 1.1em;\n  font-weight: 650;\n}\n\n.inv-pbl-header-invid {\n  font-size: 1em;\n  font-weight: 650;\n  color: blue;\n}\n\n.inv-pbl-vend-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.inv-pbl-vend-txt {\n  font-size: 1em;\n  font-weight: 600;\n}\n\n.pbl-line-list-header {\n  font-size: 1em;\n  font-weight: 650;\n  margin-left: 20px;\n  margin-right: -20px;\n}\n\n.pbl-topay-row-pos {\n    margin-top: 10px;\n}\n\n.pbl-pay-panel-pos {\n    margin-top: 0px;\n}\n\n.pbl-pay-note {\n    width: 100%;\n}\n\n.pbl-note-pos {\n    margin-top: 10px;\n}\n\n.pbl-pay-date-pos {\n  margin-top: 10px;\n}\n\n.pbl-payment-msg{\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.pbl-payment-msg-err{\n  font-size: 1em;\n  font-weight: 650;\n  color: red;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-return-box {
  display: block;
  /* margin: auto; */
  text-align: center;
  width: 70%;
  height: 400px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  /* position: absolute; */
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 25px;
}

.return-clause_textarea {
  width: 90%;
}

.return-by-month {
  width: 30%;
}

.return-setup-row {
  margin-bottom: 15px;
}

.admin-ret-setup-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 25px;
}

.admin-return-setup-lbl {
  font-size: 1em;
  font-weight: 650;
}

.admin-ret-char-msg {
  font-size: 0.9em;
  font-weight: 550;
  margin-top: -15px;
}

.admin-ret-setup-msg {
  margin-top: 10px;
  font-size: 1em;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/administration/returns/ReturnSetup.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,wBAAwB;EACxB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".admin-return-box {\n  display: block;\n  /* margin: auto; */\n  text-align: center;\n  width: 70%;\n  height: 400px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  /* position: absolute; */\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 2%;\n  margin-bottom: 25px;\n}\n\n.return-clause_textarea {\n  width: 90%;\n}\n\n.return-by-month {\n  width: 30%;\n}\n\n.return-setup-row {\n  margin-bottom: 15px;\n}\n\n.admin-ret-setup-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 25px;\n}\n\n.admin-return-setup-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.admin-ret-char-msg {\n  font-size: 0.9em;\n  font-weight: 550;\n  margin-top: -15px;\n}\n\n.admin-ret-setup-msg {\n  margin-top: 10px;\n  font-size: 1em;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

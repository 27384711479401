// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.liability-main-box {
    display: block;
    /* margin: auto; */
    text-align: center;
    width: 100%;
    height: 640px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #e4e8f0;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    /* position: absolute; */
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .lia-header{
    font-size: 1.1em;
    font-weight: 650;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/liability/LiabilityMain.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,8EAA8E;IAC9E,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB","sourcesContent":[".liability-main-box {\n    display: block;\n    /* margin: auto; */\n    text-align: center;\n    width: 100%;\n    height: 640px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #e4e8f0;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    /* position: absolute; */\n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 5px;\n    margin-bottom: 10px;\n  }\n\n  .lia-header{\n    font-size: 1.1em;\n    font-weight: 650;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-search-item-cat {
    font-size: .8em;
    font-weight: 600;
}

.login-search-item-name {
    font-size: 1em;
    font-weight: 650;
}

.react-load-login-pos {
    margin-left: 50%;
    margin-top: 10%;
}

.login-search-biz-lines-row3 {

}

@media only screen and (max-device-width: 480px) {

    .login-search-item-cat {
        font-size: .7em;
        font-weight: 550;
    }
    
    .login-search-item-name {
        font-size: .9em;
        font-weight: 600;
    }

    .login-search-biz-lines-row3 {
       margin-top: -3px;
    }
}`, "",{"version":3,"sources":["webpack://./src/utils/search/view/ViewItemList.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;AAEA;;AAEA;;IAEI;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;OACG,gBAAgB;IACnB;AACJ","sourcesContent":[".login-search-item-cat {\n    font-size: .8em;\n    font-weight: 600;\n}\n\n.login-search-item-name {\n    font-size: 1em;\n    font-weight: 650;\n}\n\n.react-load-login-pos {\n    margin-left: 50%;\n    margin-top: 10%;\n}\n\n.login-search-biz-lines-row3 {\n\n}\n\n@media only screen and (max-device-width: 480px) {\n\n    .login-search-item-cat {\n        font-size: .7em;\n        font-weight: 550;\n    }\n    \n    .login-search-item-name {\n        font-size: .9em;\n        font-weight: 600;\n    }\n\n    .login-search-biz-lines-row3 {\n       margin-top: -3px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

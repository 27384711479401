// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cust-communiity-contact-head{
    font-size: 1.1em;
    font-weight: 650;
    margin-top: 10px;
    margin-bottom: 20px;
}

.cont-row-pos {
    margin-top: 10px;
}

.comm-contact-lbl {
    font-size: 1em;
    font-weight: 650;
}

.comm-contact-text {
    font-size: 1em;
    font-weight: 650;
    color: #345373;
}

.cust-contact-exit {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/customerReports/contacts/CommunityContact.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".cust-communiity-contact-head{\n    font-size: 1.1em;\n    font-weight: 650;\n    margin-top: 10px;\n    margin-bottom: 20px;\n}\n\n.cont-row-pos {\n    margin-top: 10px;\n}\n\n.comm-contact-lbl {\n    font-size: 1em;\n    font-weight: 650;\n}\n\n.comm-contact-text {\n    font-size: 1em;\n    font-weight: 650;\n    color: #345373;\n}\n\n.cust-contact-exit {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchant-inv-receivable-box {
    width: 100%;
    height: 400px;
    max-height: 500px;
    background-color: #f5f2eb;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid gray;
    margin-top: 5px;
    margin-bottom: 10px;
  }


.merchant-receive-header {
    font-size: 1em;
    font-weight: 650;
    margin-top: 10px;
    margin-bottom: -10px;
}

.merchant-receive-header {
    font-size: 1em;
    font-weight: 650;
    margin-bottom: -5px;
}

.recv-row-color-dr {
    background-color: #c1d7db;
    height: 40px;
}

.recv-row-color-lt {
    background-color: #edf8fa;
    height: 40px;
}

.merchant-recv-lbl1 {
    font-size: 1em;
    font-family: 600;
    padding-left: 3%;
    margin-top: 5px;
    margin-bottom: -5px;
}

.merchant-recv-lbl2 {
    font-size: 1em;
    font-family: 600;
    
    margin-top: 5px;
    margin-bottom: -5px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/receivable/ListReceivable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,mBAAmB;EACrB;;;AAGF;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;;IAEhB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".merchant-inv-receivable-box {\n    width: 100%;\n    height: 400px;\n    max-height: 500px;\n    background-color: #f5f2eb;\n    overflow-x: hidden;\n    overflow-y: auto;\n    border: 1px solid gray;\n    margin-top: 5px;\n    margin-bottom: 10px;\n  }\n\n\n.merchant-receive-header {\n    font-size: 1em;\n    font-weight: 650;\n    margin-top: 10px;\n    margin-bottom: -10px;\n}\n\n.merchant-receive-header {\n    font-size: 1em;\n    font-weight: 650;\n    margin-bottom: -5px;\n}\n\n.recv-row-color-dr {\n    background-color: #c1d7db;\n    height: 40px;\n}\n\n.recv-row-color-lt {\n    background-color: #edf8fa;\n    height: 40px;\n}\n\n.merchant-recv-lbl1 {\n    font-size: 1em;\n    font-family: 600;\n    padding-left: 3%;\n    margin-top: 5px;\n    margin-bottom: -5px;\n}\n\n.merchant-recv-lbl2 {\n    font-size: 1em;\n    font-family: 600;\n    \n    margin-top: 5px;\n    margin-bottom: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from "react-loading";

import "./PayUsingCC.css";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";

// const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const receiveCCPayment = "/routes/stripe/receiveCCPayment";

const CCPayoutForm = (props) => {
  // console.log("props.stripeaccId:", props.stripeaccId);
  const stripe = useStripe();
  const elements = useElements();

  let amount = props.chargeAmount;

  // console.log("###### props:", props);

  const [valueof, setValueof] = useState(true);
  const [message, setMessage] = useState(0);
  const [errmsg, setErrmsg] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [isShownPay, setIsShownPay] = useState(false);
  // const [isShownCancel, setIsShownCancel] = useState(false);
  // const [isShownFinito, setIsShownFinito] = useState(false);

  const handleSubmit = async (event) => {
    // console.log("stripeaccId props:", props.stripaccId);
    event.preventDefault();
    setMessage(1);

    setSpinner(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    setSpinner(false);

    if (!error) {
      const { id } = paymentMethod;
      // console.log("paymentMethod id:", id);
      try {
        let inpdata = {
          cardId: id,
          stripeAccountId: props.stripeaccId,
          amount: props.chargeAmount,
          platformFee: props.platformFee,
          communityId: props.commounityId,
          baandaId: props.baandaId,
          description: props.description,
          callingModule: props.callingModule,
        };
        // console.log(">>>>> data:", inpdata);
        let url = baandaServer + receiveCCPayment;
        // console.log(">>>>> url:", url);
        setSpinner(true);
        await axios.post(url, inpdata);
        // const { data } = await axios.post(url, inpdata);
        setSpinner(false);
        // console.log("data:", data);
        setValueof(false);
      } catch (err) {
        console.log("err:", err.message);
        setSpinner(false);
        setMessage(0);
        setErrmsg(error.message);
      }
    } else {
      console.log("createPaymentMethod error:", error);
      setSpinner(false);
      setMessage(0);
      setErrmsg(error.message);
    }
  };
  // console.log(">>>> valueof:", valueof);

  let buttonPanel;
  if (message === 0) {
    buttonPanel = (
      <div className="test-pay-button-place">
        <button
          // type="submit" className="btn_cc_pay" disabled={!stripe}
          // style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          type="submit"
          className={
            isShownPay ? "btn_cc_pay_inv_active" : "btn_cc_pay_inv_passive"
          }
          disabled={!stripe}
          onMouseEnter={() => setIsShownPay(true)}
          onMouseLeave={() => setIsShownPay(false)}
        >
          Pay
        </button>
        <div>{errmsg}</div>
      </div>
    );
  }

  let uploadingSpin;
  if (spinner) {
    // console.log('in spiller setup')
    uploadingSpin = (
      <div>
        <ReactLoading
          type={"spokes"}
          color={"#ffffff"}
          height={30}
          width={30}
        />
      </div>
    );
  }

  let creditCardPanel;
  if (valueof) {
    creditCardPanel = (
      <div>
        <form
          onSubmit={handleSubmit}
          // style={{ maxWidth: "300px", margin: "0 auto" }}
        >
          <div className="cc-msg">
            {/* Amount to pay is ${(amount / 100).toFixed(2)} */}
            Amount to pay is ${amount.toFixed(2)}
          </div>
          <CardElement className="some-backx" />
          {buttonPanel}
        </form>
        <div className="text-center spin_position">{uploadingSpin}</div>
      </div>
    );
  } else {
    creditCardPanel = (
      <div>
        <h3>Thanks for your payment</h3>
      </div>
    );
  }

  return <div>{creditCardPanel}</div>;
};

//=====================================================================
// Calls the function, invoked from other cmomponent, to expose CC form.
const PayUsingCC = (props) => {
  // console.log("PayUsingCC props:", props);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: props.stripaccId,
  });

  return (
    <div>
      <Elements stripe={stripePromise}>
        <CCPayoutForm
          chargeAmount={props.amount}
          platformFee={props.platformFee}
          stripeaccId={props.stripaccId}
          commounityId={props.communityId}
          description={props.description}
          baandaId={props.baandaId}
          callingModule={props.callingModule}
        />
      </Elements>
    </div>
  );
};

export default PayUsingCC;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-email-owner-box {
  display: block;

  text-align: center;
  width: 60%;
  height: 400px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: absolute;
  z-index: 500;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-left: auto;
      margin-right: auto;
      margin-top: 0%;
      margin-bottom: 25px; */
  margin: auto;
}

.input_email_text_aa {
  width: 100%;
  resize: none;
  font-size: 0.9em;
  font-weight: 600;
}

.landing-subject-txt {
  width: 60%;
  font-size: 0.9em;
  font-weight: 600;
}

.land-sub-pos {
  margin-top: 5px;
  margin-bottom: 10px;
}

.landing-email-owner-msg {
  font-size: 1em;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: -5px;
}

.landing-email-owner-msg-err {
  font-size: 1em;
  font-weight: 600;
  color: red;
  margin-top: 5px;
  margin-bottom: -5px;
}

.send-email-to-owner-lbl {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/landing/SendEmailToOwner.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,8EAA8E;EAC9E,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB;;;4BAG0B;EAC1B,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;EACV,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".landing-email-owner-box {\n  display: block;\n\n  text-align: center;\n  width: 60%;\n  height: 400px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #fff;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  position: absolute;\n  z-index: 500;\n  overflow-y: auto;\n  overflow-x: hidden;\n  /* margin-left: auto;\n      margin-right: auto;\n      margin-top: 0%;\n      margin-bottom: 25px; */\n  margin: auto;\n}\n\n.input_email_text_aa {\n  width: 100%;\n  resize: none;\n  font-size: 0.9em;\n  font-weight: 600;\n}\n\n.landing-subject-txt {\n  width: 60%;\n  font-size: 0.9em;\n  font-weight: 600;\n}\n\n.land-sub-pos {\n  margin-top: 5px;\n  margin-bottom: 10px;\n}\n\n.landing-email-owner-msg {\n  font-size: 1em;\n  font-weight: 600;\n  margin-top: 5px;\n  margin-bottom: -5px;\n}\n\n.landing-email-owner-msg-err {\n  font-size: 1em;\n  font-weight: 600;\n  color: red;\n  margin-top: 5px;\n  margin-bottom: -5px;\n}\n\n.send-email-to-owner-lbl {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

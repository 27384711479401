// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipping-manual-box {
  display: block;
  text-align: center;
  width: 90%;
  height: 540px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 15;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  margin-bottom: 25px;
}

.man-ship-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-top: 5px;
  margin-bottom: -5px;
}

.ship-man-city {
  width: 80%;
}

.ship-manual-lbl {
  font-size: 1em;
  font-weight: 650;
}

.ship-man-state {
    width:70%;
}

.ship-man-zip {
    width:80%;
}

.ship-man-country {
    width: 60%;
}

.to-manual-panel-pos {
    margin-top: 15px;
}

.ship-man-lineitem {
    width: 90%;
}

.man-ship-confirm {
    font-size: 1em;
    font-weight: 650;
}

.manual-phone-flag {
  margin-top: 3px;
  width: 50%;
  padding-left: 10%;
  font-size: .9em;
  font-weight: 550;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/delivery/ship/ManualShipping.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".shipping-manual-box {\n  display: block;\n  text-align: center;\n  width: 90%;\n  height: 540px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 15;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 1%;\n  margin-bottom: 25px;\n}\n\n.man-ship-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-top: 5px;\n  margin-bottom: -5px;\n}\n\n.ship-man-city {\n  width: 80%;\n}\n\n.ship-manual-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.ship-man-state {\n    width:70%;\n}\n\n.ship-man-zip {\n    width:80%;\n}\n\n.ship-man-country {\n    width: 60%;\n}\n\n.to-manual-panel-pos {\n    margin-top: 15px;\n}\n\n.ship-man-lineitem {\n    width: 90%;\n}\n\n.man-ship-confirm {\n    font-size: 1em;\n    font-weight: 650;\n}\n\n.manual-phone-flag {\n  margin-top: 3px;\n  width: 50%;\n  padding-left: 10%;\n  font-size: .9em;\n  font-weight: 550;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

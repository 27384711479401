// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legacy-tr-box {
    display: block;
    text-align: center;
    width: 100%;
    height: 600px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 20;
    font-size: 1em;
    font-weight: 600;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 25px;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/receivable/legacy/LegacyTransaction.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,8EAA8E;IAC9E,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".legacy-tr-box {\n    display: block;\n    text-align: center;\n    width: 100%;\n    height: 600px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #fff;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    z-index: 20;\n    font-size: 1em;\n    font-weight: 600;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 0;\n    margin-bottom: 25px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

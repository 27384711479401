// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coop-mgmt-agreement-box {
    display: block;
    text-align: center;
    width: 100%;
    height: 270px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #fff;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
  }

  .coop-mgmt-agr-view-x{
    font-size: 1em;
    font-weight: 650;
    margin-bottom: 10px;
  }


  .coop-view-qna-row{
    padding-top: 5px;
    padding-bottom: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/administration/coopStore/ViewQnAAgreement.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,mBAAmB;EACrB;;;EAGA;IACE,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":[".coop-mgmt-agreement-box {\n    display: block;\n    text-align: center;\n    width: 100%;\n    height: 270px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #fff;\n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-top: 10px;\n  }\n\n  .coop-mgmt-agr-view-x{\n    font-size: 1em;\n    font-weight: 650;\n    margin-bottom: 10px;\n  }\n\n\n  .coop-view-qna-row{\n    padding-top: 5px;\n    padding-bottom: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixedsize-coop-pickup-list-x {
  width: 100%;
  height: 80px;
  max-height: 350px;
  background-color: #fff;
  font-size: 0.9em;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 15px;
}

.coop-pickup-location-name {
  font-size: 1em;
  font-weight: 650;
}

.coop-admin-pickup-addr-lbl {
  font-size: 1em;
  font-weight: 650;
  margin-top: 5px;
}

.coop-pickup-message {
  font-size: 1em;
  font-weight: 650;
}

.coop-pickup-message-err {
  font-size: 1em;
  font-weight: 650;
  color: red;
}

.coop-default-save-btn-pos {
    margin-top: -15px;
}

.keep-pickup-del-pos {
  margin-top: 1px;
  margin-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/coop/delivery/DeliveryDefaultSetup.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;AACZ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".fixedsize-coop-pickup-list-x {\n  width: 100%;\n  height: 80px;\n  max-height: 350px;\n  background-color: #fff;\n  font-size: 0.9em;\n  overflow-x: hidden;\n  overflow-y: auto;\n  border: 1px solid gray;\n  margin-top: 15px;\n}\n\n.coop-pickup-location-name {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.coop-admin-pickup-addr-lbl {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: 5px;\n}\n\n.coop-pickup-message {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.coop-pickup-message-err {\n  font-size: 1em;\n  font-weight: 650;\n  color: red;\n}\n\n.coop-default-save-btn-pos {\n    margin-top: -15px;\n}\n\n.keep-pickup-del-pos {\n  margin-top: 1px;\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-letter-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-top: -10px;
  margin-bottom: 10px;
}

.news-letter-name {
  font-size: 1em;
  font-weight: 650;
  margin-top: -10px;
  margin-bottom: 10px;
  color: blue;
}

.basic-compose-header-name {
  font-size: .9em;
  font-weight: 650;
  margin-top: -5px;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/newsLetter/NewsLetCompose.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".news-letter-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-top: -10px;\n  margin-bottom: 10px;\n}\n\n.news-letter-name {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: -10px;\n  margin-bottom: 10px;\n  color: blue;\n}\n\n.basic-compose-header-name {\n  font-size: .9em;\n  font-weight: 650;\n  margin-top: -5px;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

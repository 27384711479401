// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.broadcast-purchase-box {
  display: block;

  text-align: center;
  width: 90%;
  height: 540px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: absolute;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-left: auto;
    margin-right: auto;
    margin-top: 0%;
    margin-bottom: 25px; */
  margin: auto;
}

.brd-buy-target-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-top: -8px;
}

.brd-mgmt-target-list {
  font-size: 1em;
  margin-top: 15px;
  width: 90%;
  display: block;
  position: absolute;
  z-index: 50;
  margin-left: 10%;
  margin-right: -10%;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/buyTarget/ManageTarget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,8EAA8E;EAC9E,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB;;;0BAGwB;EACxB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;EACV,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,8EAA8E;AAChF","sourcesContent":[".broadcast-purchase-box {\n  display: block;\n\n  text-align: center;\n  width: 90%;\n  height: 540px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #fff;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  position: absolute;\n  z-index: 100;\n  overflow-y: auto;\n  overflow-x: hidden;\n  /* margin-left: auto;\n    margin-right: auto;\n    margin-top: 0%;\n    margin-bottom: 25px; */\n  margin: auto;\n}\n\n.brd-buy-target-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-top: -8px;\n}\n\n.brd-mgmt-target-list {\n  font-size: 1em;\n  margin-top: 15px;\n  width: 90%;\n  display: block;\n  position: absolute;\n  z-index: 50;\n  margin-left: 10%;\n  margin-right: -10%;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

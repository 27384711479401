import React, { Component } from "react";

import axios from "axios";

import "./VideoReview.css";

import fbicon from "../../../../../../../../images/icons8-facebook-48.png";
import insticon from "../../../../../../../../images/instagram.png";
import twiticon from "../../../../../../../../images/icons8-twitter-48.png";
import youicon from "../../../../../../../../images/youtube.png";
import storeicon from "../../../../../../../../images/store.jpg";
import websiteicon from "../../../../../../../../images/website.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCampaignFooter = "/routes/interact/getCampaignFooter?";

class VideoReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReviewFlag: true,

      bannerLocation: "",
      posterLocation: "",
      videoLocation: "",
      videoType: "",

      introbgColor: "",
      isPersonal: false,
      salute: "",
      signoff: "",

      footer: null,

      bodyForm: null,
      slides: [],

      VideoReviewFrameColor: "#000",
    };
  }

  componentDidMount = async () => {
    let bannerLocation = "";
    // let imageLocation = "";
    let posterLocation = "";
    let videoLocation = "";
    let introbgColor = "";
    let isPersonal = false;
    let salute = "";
    let bodyForm = null;
    let videoType = "";

    let VideoReviewFrameColor = "#000";
    if (this.props.theBroadcast.presentationFrameColor)
      VideoReviewFrameColor = this.props.theBroadcast.presentationFrameColor;
    let bmc = this.props.theBroadcast.mailComposition;
    let slides = [];
    if (bmc) {
      if (this.props.theBroadcast.mailComposition.fileUploads.length > 0) {
        this.props.theBroadcast.mailComposition.fileUploads.forEach((obj) => {
          if (obj.compositionComponent === "banner") {
            bannerLocation = obj.location;
          }
          if (obj.compositionComponent === "poster") {
            posterLocation = obj.location;
          }
          if (obj.compositionComponent === "video") {
            videoLocation = obj.location;
            let typesp = obj.key.split(".");
            videoType = "video/" + typesp[1];
          }
        });
      }

      introbgColor = "white";
      if (bmc.interactionType === "personal") {
        isPersonal = true;
        salute = bmc.salute + " " + this.props.yourName + ", ";
      }

      bodyForm = bmc.introBodyFormat;
    }

    await this.getFooter();

    this.setState({
      bannerLocation,
      posterLocation,
      videoLocation,
      videoType,
      VideoReviewFrameColor,
      introbgColor,
      isPersonal,
      salute,
      bodyForm,
      slides,
    });
  };

  getFooter = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getCampaignFooter + param;
    try {
      let fret = await axios.get(url);
      if (fret.data.status === "success") {
        this.setState({
          footer: fret.data.Msg.campaignFooterObj,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: fret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  exitFromPopup = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("VideoReview...");

    let backFromTemplatePop = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitFromPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let bannerPanel;
    if (this.state.bannerLocation) {
      bannerPanel = (
        <div className="text-center">
          <img
            src={this.state.bannerLocation}
            alt="banner"
            hight="50"
            width="457"
            className="video-review-banner-pos"
          />
        </div>
      );
    }

    let bcb = this.props.theBroadcast.mailComposition;

    let introBodyFontSize, introBodyFontFamily;
    if (this.state.bodyForm) {
      introBodyFontSize = this.state.bodyForm.fontSize;
      introBodyFontFamily = this.state.bodyForm.fontFamily;
    }

    let titleFontSize,
      titleColor,
      titlebgColor,
      titleIsBold,
      titleFontFamily,
      titleAlign;
    if (bcb.introTitleFormat) {
      titleFontSize = bcb.introTitleFormat.fontSize;
      titleColor = bcb.introTitleFormat.color;
      titlebgColor = bcb.introTitleFormat.bgColor;
      titleIsBold = bcb.introTitleFormat.isBold;
      titleFontFamily = bcb.introTitleFormat.fontFamily;
      titleAlign = bcb.introTitleFormat.align;
    }

    let introTitlePanel;
    if (bcb.introTitle !== "") {
      introTitlePanel = (
        <div
          style={{
            backgroundColor: titlebgColor,
            width: "457px",
            fontSize: titleFontSize,
            fontFamily: titleFontFamily,
            color: titleColor,
            textAlign: titleAlign,
            marginLeft: "-6px",
            paddingLeft: "5px",
          }}
          className=""
        >
          {titleIsBold ? <b>{bcb.introTitle}</b> : bcb.introTitle}
        </div>
      );
    }

    let introPersonalizePanel;
    if (this.state.isPersonal) {
      introPersonalizePanel = (
        <div
          style={{
            backgroundColor: this.state.introbgColor,
            width: "457px",
            fontSize: introBodyFontSize,
            fontFamily: introBodyFontFamily,
            marginLeft: "-6px",
            paddingLeft: "5px",
          }}
        >
          <div>{this.state.salute}</div>
          <div>&nbsp;</div>
        </div>
      );
    }

    let introPanel;
    introPanel = (
      <div
        style={{
          backgroundColor:
            this.props.theBroadcast.mailComposition.introBodyFormat.bgColor,
          width: "457px",
          fontSize:
            this.props.theBroadcast.mailComposition.introBodyFormat.fontSize,
          paddingLeft: "5px",
          paddingRight: "10px",
          fontFamily:
            this.props.theBroadcast.mailComposition.introBodyFormat.fontFamily,
          color: this.props.theBroadcast.mailComposition.introBodyFormat.color,
          marginLeft: "-6px",
        }}
      >
        <div> {bcb.introBody} </div>
        <div>&nbsp;</div>
      </div>
    );

    let topPanel;
    if (introTitlePanel || introPersonalizePanel || introPanel)
      topPanel = (
        <div
          style={{
            backgroundColor: this.state.introbgColor,

            width: "457px",
            marginLeft: "7px",
            paddingLeft: "6px",
            color: "black",
          }}
          className="video-intro-box"
        >
          {introTitlePanel}
          {introPersonalizePanel}
          {introPanel}
        </div>
      );

    let videoPanel;
    if (this.state.videoLocation !== "") {
      videoPanel = (
        <div className="video-container">
          <video
            width="425"
            height="290"
            controls
            poster={this.state.posterLocation}
          >
            <source
              src={this.state.videoLocation}
              type={this.state.videoType}
            />
          </video>
        </div>
      );
    }

    let signoffPanel;
    if (
      this.props.theBroadcast.mailComposition.interactionType === "personal"
    ) {
      signoffPanel = (
        <div
          style={{
            backgroundColor: this.state.introbgColor,
            width: "457px",
            marginLeft: "4px",
            paddingLeft: "20px",
            color: "black",
          }}
          className="video-signoff-pos"
        >
          <div className="row">
            <div className="col text-left slider-comp-signoff-rev">
              {this.props.theBroadcast.mailComposition.signoff}
            </div>
          </div>
          <div className="row">
            <div className="col text-left slider-comp-signoff-name">
              {this.props.theBroadcast.mailComposition.senderName}
            </div>
          </div>
          {this.props.theBroadcast.mailComposition.senderPosition ===
          "" ? null : (
            <div className="row">
              <div className="col text-left slider-comp-signoff-position">
                ( {this.props.theBroadcast.mailComposition.senderPosition} )
              </div>
            </div>
          )}
        </div>
      );
    }

    let footerPanel;
    if (this.state.footer) {
      footerPanel = (
        <div
          className="video-footer-box"
          style={{
            backgroundColor: "#ffffff",
            marginLeft: "3px",
            paddingLeft: "3px",
            paddingRight: "15px",
            width: "457px",
          }}
        >
          <div className="text-center">
            {this.state.footer.activeFacebook ? (
              <a
                href={this.state.footer.facebookLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={fbicon} alt="fbicon" hight="auto" width="30px" />
              </a>
            ) : null}
            &nbsp;
            {this.state.footer.activeInstagram ? (
              <a
                href={this.state.footer.instagramLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={insticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeTwitter ? (
              <a
                href={this.state.footer.twitterLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={twiticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeYoutube ? (
              <a
                href={this.state.footer.youtubeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={youicon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeStore ? (
              <a
                href={this.state.footer.storeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={storeicon} alt="insticon" hight="auto" width="35px" />
              </a>
            ) : null}
            {this.state.footer.activeWebsite ? (
              <a
                href={this.state.footer.websiteLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={websiteicon}
                  alt="insticon"
                  hight="auto"
                  width="35px"
                />
              </a>
            ) : null}
          </div>
          <div className="text-center comp-footer-msg">
            <p className="comp-footer-padding" align="justify">
              {this.state.footer.footerMessage}
            </p>
          </div>
          <div className="text-center comp-unsubscribe-msg">
            <u>Unsubscribe</u>
          </div>
        </div>
      );
    }

    let showTemplatePanel;
    showTemplatePanel = (
      <div
        style={{
          backgroundColor: this.state.VideoReviewFrameColor,
        }}
        className="slider-email-review"
      >
        <div className="slider-pop-header text-center">
          Video Review &nbsp;{backFromTemplatePop}
        </div>
        {bannerPanel}
        {topPanel}
        {videoPanel}
        {signoffPanel}
        {footerPanel}
      </div>
    );

    let outputPanel;
    outputPanel = <div>{showTemplatePanel}</div>;

    return <div>{outputPanel}</div>;
  }
}

export default VideoReview;

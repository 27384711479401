// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-list-cust-box {
  display: block;
  text-align: center;
  width: 80%;
  height: 540px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 25px;
}

.fixedsize-cust-reports-list {
  width: 100%;
  height: 420px;
  max-height: 420px;
  background-color: #f5f2eb;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 15px;
}

@media only screen and (max-device-width: 480px) {
  .report-list-cust-box {
    display: block;
    text-align: center;
    width: 100%;
    height: 540px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #e4e8f0;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/customerReports/customerReportLanding.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB;sCACkC;IAClC,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;AACF","sourcesContent":[".report-list-cust-box {\n  display: block;\n  text-align: center;\n  width: 80%;\n  height: 540px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 2%;\n  margin-bottom: 25px;\n}\n\n.fixedsize-cust-reports-list {\n  width: 100%;\n  height: 420px;\n  max-height: 420px;\n  background-color: #f5f2eb;\n  overflow-x: hidden;\n  overflow-y: auto;\n  border: 1px solid gray;\n  margin-top: 15px;\n}\n\n@media only screen and (max-device-width: 480px) {\n  .report-list-cust-box {\n    display: block;\n    text-align: center;\n    width: 100%;\n    height: 540px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #e4e8f0;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5),\n      0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 2%;\n    margin-bottom: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

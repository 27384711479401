// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-review-size {
  margin: auto;
  height: 320;
  width: 425;
}

.video-signoff-pos {
  margin-top: 0%;
}

.video-container {
  align-content: center;
  padding-left: 4%;
  margin-top: -4%;
}

.video-footer-box {
  width: 457px;
  height: auto;
  background-color: lightblue;
  border: 1px dashed gray;
  margin-left: 0px;
  padding-top: 5px;
  margin-top: 2%;
}

.video-review-banner-pos {
  margin-top: -2px;
  margin-left: 7px;
}

.video-intro-box {
  /* width: 460px;  */
  height: auto;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/video/review/VideoReview.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".video-review-size {\n  margin: auto;\n  height: 320;\n  width: 425;\n}\n\n.video-signoff-pos {\n  margin-top: 0%;\n}\n\n.video-container {\n  align-content: center;\n  padding-left: 4%;\n  margin-top: -4%;\n}\n\n.video-footer-box {\n  width: 457px;\n  height: auto;\n  background-color: lightblue;\n  border: 1px dashed gray;\n  margin-left: 0px;\n  padding-top: 5px;\n  margin-top: 2%;\n}\n\n.video-review-banner-pos {\n  margin-top: -2px;\n  margin-left: 7px;\n}\n\n.video-intro-box {\n  /* width: 460px;  */\n  height: auto;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.receiveable-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-top: 10px;
  margin-bottom: 1px;
}

.rec-main-select-ops-row {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/receivable/ReceiveMain.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["\n.receiveable-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-top: 10px;\n  margin-bottom: 1px;\n}\n\n.rec-main-select-ops-row {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

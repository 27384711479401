// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.return-customer-box {
  display: block;
  text-align: center;
  width: 90%;
  height: 630px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 15;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: 25px;
}

.cust-return-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 10px;
}

.cust-ret-mgmt-action-row {
  margin-top: -5px;
}

@media only screen and (max-device-width: 480px) {
  .return-customer-box {
    display: block;
    text-align: center;
    width: 105%;
    height: 570px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #e4e8f0;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 15;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -2%;
    margin-right: auto;
    margin-top: 0%;
    margin-bottom: 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/action/customer/returns/CustomerReturnMgmt.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,8EAA8E;IAC9E,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;AACF","sourcesContent":[".return-customer-box {\n  display: block;\n  text-align: center;\n  width: 90%;\n  height: 630px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 15;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 0%;\n  margin-bottom: 25px;\n}\n\n.cust-return-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 10px;\n}\n\n.cust-ret-mgmt-action-row {\n  margin-top: -5px;\n}\n\n@media only screen and (max-device-width: 480px) {\n  .return-customer-box {\n    display: block;\n    text-align: center;\n    width: 105%;\n    height: 570px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #e4e8f0;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    z-index: 15;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: -2%;\n    margin-right: auto;\n    margin-top: 0%;\n    margin-bottom: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-rep-details-box {
  display: block;

  text-align: center;
  width: 70%;
  height: 660px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  z-index: 10;
  /* overflow-y: auto;
    overflow-x: hidden; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 25px;
}

.fixedsize-cust-pur-box {
  width: 100%;
  height: 200px;
  max-height: 620px;
  background-color: #f5f2eb;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid gray;
  margin-top: 15px;
  align-items: center;
}

.cust-rep-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 15px;
}

.rept-cust-detail-lbl {
  font-size: 1em;
  font-weight: 650;
}

.rept-cust-detail-txt {
  font-size: 1em;
  font-weight: 650;
  color: #243a82;
}

.rep-cust-list-head {
  font-size: 1em;
  font-weight: 650;
}

.rep-cust-list-ref-head {
    font-size: 1em;
    font-weight: 650;
    margin-top: 15px;
    margin-bottom: -10px;
}

.cust-rep-refund-pos {
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/reports/customer/CustomerDetail.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;;EAElB,WAAW;EACX;yBACuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".customer-rep-details-box {\n  display: block;\n\n  text-align: center;\n  width: 70%;\n  height: 660px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n\n  z-index: 10;\n  /* overflow-y: auto;\n    overflow-x: hidden; */\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 2%;\n  margin-bottom: 25px;\n}\n\n.fixedsize-cust-pur-box {\n  width: 100%;\n  height: 200px;\n  max-height: 620px;\n  background-color: #f5f2eb;\n  overflow-x: hidden;\n  overflow-y: auto;\n  border: 1px solid gray;\n  margin-top: 15px;\n  align-items: center;\n}\n\n.cust-rep-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 15px;\n}\n\n.rept-cust-detail-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.rept-cust-detail-txt {\n  font-size: 1em;\n  font-weight: 650;\n  color: #243a82;\n}\n\n.rep-cust-list-head {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.rep-cust-list-ref-head {\n    font-size: 1em;\n    font-weight: 650;\n    margin-top: 15px;\n    margin-bottom: -10px;\n}\n\n.cust-rep-refund-pos {\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

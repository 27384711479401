// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.init-finstate-box {
  display: block;
  text-align: center;
  width: 90%;
  height: 540px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 25px;
}

.init-finstate-head {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 15px;
}

.fin-state-label {
  font-size: 1em;
  font-weight: 650;
}

.fin-state-row-pos {
  margin-top: 10px;
}

.finstate-ref-docid {
  width: 40%;
}

.finstate-amount {
  width: 25%;
}

.finstate-description {
  width: 90%;
}

.finstate-row-pos {
  margin-top: 15px;
}

.finstate-confirm {
  font-size: 1em;
  font-weight: 650;
}

.admin-finstate-msg {
  font-size: 0.9em;
  font-weight: 600;
  color: black;
}

.admin-finstate-msg-err {
  font-size: 0.9em;
  font-weight: 600;
  color: red;
}

.adm-fin-phoneFlag {
  width: 40%;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/administration/receivable/InitFinancialState.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".init-finstate-box {\n  display: block;\n  text-align: center;\n  width: 90%;\n  height: 540px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 2%;\n  margin-bottom: 25px;\n}\n\n.init-finstate-head {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 15px;\n}\n\n.fin-state-label {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.fin-state-row-pos {\n  margin-top: 10px;\n}\n\n.finstate-ref-docid {\n  width: 40%;\n}\n\n.finstate-amount {\n  width: 25%;\n}\n\n.finstate-description {\n  width: 90%;\n}\n\n.finstate-row-pos {\n  margin-top: 15px;\n}\n\n.finstate-confirm {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.admin-finstate-msg {\n  font-size: 0.9em;\n  font-weight: 600;\n  color: black;\n}\n\n.admin-finstate-msg-err {\n  font-size: 0.9em;\n  font-weight: 600;\n  color: red;\n}\n\n.adm-fin-phoneFlag {\n  width: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-placement {
    margin-top: 20px;
    display: "flex";
    justify-content: "center";
  }
  
  .dots-container-styles {
    display: flex;
    justify-content: center;
    background-color: #000;
  }
  
  .dot-style-slider {
    margin: 0 3px;
    cursor: pointer;
    background-color: #000;
    font-size: 1em;
    color: #fff;
  }
  
  .slider-style {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
  }
  
  .fixedsize-catshow-descxxx {
    height: 200px;
    max-height: 200px;
    width: 100%;
    padding-right: 4%;
    white-space: pre-line;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .frame-writeup-show {
    white-space: pre-line;
    /* border: 1px solid gray; */
  }
  
  .frame-dir-pos {
    margin-bottom: -20%;
  }

  .slider-text-box{
    border: 1px solid gray;
    height: 100px;
    /* overflow-x: hidden;
    overflow-y: auto; */
  }
  `, "",{"version":3,"sources":["webpack://./src/utils/campaign/ImageSlider.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,cAAc;IACd,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,4BAA4B;EAC9B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb;uBACmB;EACrB","sourcesContent":[".slider-placement {\n    margin-top: 20px;\n    display: \"flex\";\n    justify-content: \"center\";\n  }\n  \n  .dots-container-styles {\n    display: flex;\n    justify-content: center;\n    background-color: #000;\n  }\n  \n  .dot-style-slider {\n    margin: 0 3px;\n    cursor: pointer;\n    background-color: #000;\n    font-size: 1em;\n    color: #fff;\n  }\n  \n  .slider-style {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n    background-size: cover;\n    background-position: center;\n  }\n  \n  .fixedsize-catshow-descxxx {\n    height: 200px;\n    max-height: 200px;\n    width: 100%;\n    padding-right: 4%;\n    white-space: pre-line;\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n  \n  .frame-writeup-show {\n    white-space: pre-line;\n    /* border: 1px solid gray; */\n  }\n  \n  .frame-dir-pos {\n    margin-bottom: -20%;\n  }\n\n  .slider-text-box{\n    border: 1px solid gray;\n    height: 100px;\n    /* overflow-x: hidden;\n    overflow-y: auto; */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brd-trg-view-box {
  width: 80%;
  height: 420px;
  border: 1px solid gray;
  margin-top: 5px;
  background-color: white;
  border-radius: 5px;
  border: solid 1px black;
  margin-left: 10%;
  overflow-x: hidden;
  overflow-y: auto;
}

.brd-list-view-hight {
  /* margin-top: 2px; */
  margin-bottom: 4px;
  height: 30px;
}

.new-entry-pos-tgt-view {
  margin-top: 5px;
}

.tgt-view-new-name {
  width: 70%;
  font-size: 0.9em;
}

.tgt-view-new-cell {
  width: 70%;
}

.brd-view-new-cell {
  /* margin-top: 3px; */
  width: 90%;
  /* padding-left: 10%; */
}

.brd-tg-view-msg {
  font-size: 0.9em;
  font-weight: 600;
}

.brd-tg-view-msg-err {
  font-size: 0.9em;
  font-weight: 600;
  color: red;
}

.tg-view-header {
  font-size: .95em;
  color: blue;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: -3px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/buyTarget/viewTarget/ViewATarget.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,uBAAuB;EACvB,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".brd-trg-view-box {\n  width: 80%;\n  height: 420px;\n  border: 1px solid gray;\n  margin-top: 5px;\n  background-color: white;\n  border-radius: 5px;\n  border: solid 1px black;\n  margin-left: 10%;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.brd-list-view-hight {\n  /* margin-top: 2px; */\n  margin-bottom: 4px;\n  height: 30px;\n}\n\n.new-entry-pos-tgt-view {\n  margin-top: 5px;\n}\n\n.tgt-view-new-name {\n  width: 70%;\n  font-size: 0.9em;\n}\n\n.tgt-view-new-cell {\n  width: 70%;\n}\n\n.brd-view-new-cell {\n  /* margin-top: 3px; */\n  width: 90%;\n  /* padding-left: 10%; */\n}\n\n.brd-tg-view-msg {\n  font-size: 0.9em;\n  font-weight: 600;\n}\n\n.brd-tg-view-msg-err {\n  font-size: 0.9em;\n  font-weight: 600;\n  color: red;\n}\n\n.tg-view-header {\n  font-size: .95em;\n  color: blue;\n  font-weight: 600;\n  margin-top: 3px;\n  margin-bottom: -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gl-present-box {
  display: block;
  text-align: center;
  width: 100%;
  height: 700px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  margin-bottom: 25px;
}

.gl-scroll-box {
  display: block;
  text-align: center;
  width: 100%;
  height: 600px;
  border: 2px solid gray;
  /* padding: 5px; */
  background-color: #fff;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
}

.gl-report-header {
  font-size: 1.1em;
  font-weight: 650;
}

.gl-start-date {
  font-size: 1em;
  font-weight: 650;
}

.gl-end-date {
  font-size: 1em;
  font-weight: 650;
}

.gl-line-row-dr {
  background-color: #dcecf5;
 
}

.gl-line-row-lt {
  background-color: #f2f6f7;
 
}

.gl-line-desc {
  font-size: .9em;
  font-weight: 600;
  margin-left: 5px;
  margin-right: -5px;
}

.gl-line-lbl {
  font-size: .9em;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/reports/gl/GeneralLedger.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;;AAE3B;;AAEA;EACE,yBAAyB;;AAE3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".gl-present-box {\n  display: block;\n  text-align: center;\n  width: 100%;\n  height: 700px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 1%;\n  margin-bottom: 25px;\n}\n\n.gl-scroll-box {\n  display: block;\n  text-align: center;\n  width: 100%;\n  height: 600px;\n  border: 2px solid gray;\n  /* padding: 5px; */\n  background-color: #fff;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.gl-report-header {\n  font-size: 1.1em;\n  font-weight: 650;\n}\n\n.gl-start-date {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.gl-end-date {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.gl-line-row-dr {\n  background-color: #dcecf5;\n \n}\n\n.gl-line-row-lt {\n  background-color: #f2f6f7;\n \n}\n\n.gl-line-desc {\n  font-size: .9em;\n  font-weight: 600;\n  margin-left: 5px;\n  margin-right: -5px;\n}\n\n.gl-line-lbl {\n  font-size: .9em;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-cm-box {
  display: block;
  text-align: center;
  width: 100%;
  height: 600px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 10px;
}

.fin-rec-cm-header {
  font-size: 1.1em;
  font-weight: 650;
}

.credit-memo-inv-id-pos {
  margin-top: 45px;
}

.inv-id-redit-memo {
  width: 20%;
}

.cr-memo-amt {
  width: 20%;
  font-size: 1em;
  font-weight: 550;
}

.cr-amt-row-pos {
  margin-top: 20px;
}

.cr-comment-row-pos {
  margin-top: 10px;
}

.cr-comment-txt {
  width: 100%;
  font-size: 1em;
  font-weight: 550;
}

.cm-comment-count-pos {
  font-size: .85em;
  font-weight: 500;
  margin-top: -5px;
}

.cm-post-pos {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/receivable/creditmemo/ManageCreditMemo.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".manage-cm-box {\n  display: block;\n  text-align: center;\n  width: 100%;\n  height: 600px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 5px;\n  margin-bottom: 10px;\n}\n\n.fin-rec-cm-header {\n  font-size: 1.1em;\n  font-weight: 650;\n}\n\n.credit-memo-inv-id-pos {\n  margin-top: 45px;\n}\n\n.inv-id-redit-memo {\n  width: 20%;\n}\n\n.cr-memo-amt {\n  width: 20%;\n  font-size: 1em;\n  font-weight: 550;\n}\n\n.cr-amt-row-pos {\n  margin-top: 20px;\n}\n\n.cr-comment-row-pos {\n  margin-top: 10px;\n}\n\n.cr-comment-txt {\n  width: 100%;\n  font-size: 1em;\n  font-weight: 550;\n}\n\n.cm-comment-count-pos {\n  font-size: .85em;\n  font-weight: 500;\n  margin-top: -5px;\n}\n\n.cm-post-pos {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

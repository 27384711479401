// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-test-send-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 25px;
  margin-top: 10px;
}

.basic-ts-name {
  width: 70%;
}

.basic-ts-lbl {
  font-size: 1em;
  font-weight: 650;
  margin-bottom: 20px;
}

.basic-ts-msg {
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 10px;
}

.basic-ts-msg-err {
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 10px;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/templates/review/BasicCompTestSend.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".basic-test-send-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 25px;\n  margin-top: 10px;\n}\n\n.basic-ts-name {\n  width: 70%;\n}\n\n.basic-ts-lbl {\n  font-size: 1em;\n  font-weight: 650;\n  margin-bottom: 20px;\n}\n\n.basic-ts-msg {\n  font-size: 0.9em;\n  font-weight: 600;\n  margin-top: 10px;\n}\n\n.basic-ts-msg-err {\n  font-size: 0.9em;\n  font-weight: 600;\n  margin-top: 10px;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

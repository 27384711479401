// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inventory-search-box {
    display: block;
    /* margin: auto; */
    text-align: center;
    width: 80%;
    height: 440px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #e4e8f0;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    /* position: absolute; */
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 25px;
  }

  .rep-inv-cat-box {
    width: 50%;
    height: 300px;
    border: 2px solid gray;
    padding: 5px;
    margin-top: 5px;
    background-color: rgba(242, 247, 247, 0.9);
    box-shadow: 3px 3px 0px 0px black;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: -30%;
    position: absolute;
    z-index: 45;
    margin-left: 10%;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/reports/inventory/GoodsInventory.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,8EAA8E;IAC9E,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,0CAA0C;IAC1C,iCAAiC;IACjC,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;EAClB","sourcesContent":[".inventory-search-box {\n    display: block;\n    /* margin: auto; */\n    text-align: center;\n    width: 80%;\n    height: 440px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #e4e8f0;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    /* position: absolute; */\n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 2%;\n    margin-bottom: 25px;\n  }\n\n  .rep-inv-cat-box {\n    width: 50%;\n    height: 300px;\n    border: 2px solid gray;\n    padding: 5px;\n    margin-top: 5px;\n    background-color: rgba(242, 247, 247, 0.9);\n    box-shadow: 3px 3px 0px 0px black;\n    border-radius: 5px;\n    margin-bottom: 5px;\n    margin-top: -30%;\n    position: absolute;\n    z-index: 45;\n    margin-left: 10%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

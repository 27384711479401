// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coop-store-setup-box {
    display: block;
    text-align: center;
    width: 100%;
    height: 510px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #fff;
 
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
 
  }

  .co-op-input-top-row{
    margin-top: 5%;
  }

  .co-op-input-rows{
    margin-top: 10px;
  }

  .coop-keep-show-desc{
    width: 80%
  }

  .coop-keeper-setup-city{
    width: 90%;
  }

  .coop-keeper-setup-state{
    width: 50%;
    margin-left: -15%;
  }

  .coop-keeper-setup-zip{
    width: 50%;
    margin-left: -15%;
  }

  .coop-keeper-show-name{
    width: 80%;
  }

  .coop-store-show-name {
    width: 80%;
  }

  .coop-keeper-setup-street {
    width: 80%;
  }

  .adm-coop-keep-promo-select {
    width: 60%;
    font-size: 1em;
    display: block;
    position: absolute;
    z-index: 20;
    margin-bottom: 1px;
  }

  .store-point-email-pos {
    margin-top: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/coop/StoreSetup.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;;IAEtB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;;EAEpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE;EACF;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".coop-store-setup-box {\n    display: block;\n    text-align: center;\n    width: 100%;\n    height: 510px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #fff;\n \n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n \n  }\n\n  .co-op-input-top-row{\n    margin-top: 5%;\n  }\n\n  .co-op-input-rows{\n    margin-top: 10px;\n  }\n\n  .coop-keep-show-desc{\n    width: 80%\n  }\n\n  .coop-keeper-setup-city{\n    width: 90%;\n  }\n\n  .coop-keeper-setup-state{\n    width: 50%;\n    margin-left: -15%;\n  }\n\n  .coop-keeper-setup-zip{\n    width: 50%;\n    margin-left: -15%;\n  }\n\n  .coop-keeper-show-name{\n    width: 80%;\n  }\n\n  .coop-store-show-name {\n    width: 80%;\n  }\n\n  .coop-keeper-setup-street {\n    width: 80%;\n  }\n\n  .adm-coop-keep-promo-select {\n    width: 60%;\n    font-size: 1em;\n    display: block;\n    position: absolute;\n    z-index: 20;\n    margin-bottom: 1px;\n  }\n\n  .store-point-email-pos {\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-select-pdf-box {
  width: 100%;
  height: auto;
  border: 1px solid gray;
  padding: 5px;
  margin-top: 5px;
  background-color: #dfebdf;
  color: black;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 5px;
}

.pdf-view-header {
  font-size: 1.1em;
  font-weight: 650;
}

.btn_viewpdf_apdep {
  width: 80px;
  height: 32px;
  background-color: #943b0f;
  border: none;
  color: white;
  font-size: 1em;
  font-weight: 650;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/components/fileView/ViewPdfFile.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,iCAAiC;EACjC,kBAAkB;AACpB","sourcesContent":[".view-select-pdf-box {\n  width: 100%;\n  height: auto;\n  border: 1px solid gray;\n  padding: 5px;\n  margin-top: 5px;\n  background-color: #dfebdf;\n  color: black;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-top: 30px;\n  margin-bottom: 5px;\n}\n\n.pdf-view-header {\n  font-size: 1.1em;\n  font-weight: 650;\n}\n\n.btn_viewpdf_apdep {\n  width: 80px;\n  height: 32px;\n  background-color: #943b0f;\n  border: none;\n  color: white;\n  font-size: 1em;\n  font-weight: 650;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

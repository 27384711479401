// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test-pay-button-place {
  margin-top: -15px;
}

.some-backx {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1em;
  background-color: aliceblue;
}

.cc-msg {
  font-size: 1em;
  color: white;
  font-weight: 650;
  margin-bottom: 20px;
  margin-top: 15px;
}

.platform-cc-payment-description {
  font-size: 1em;
  font-weight: 650;
  margin-top: 10px;
  color: #446d80;
}


@media only screen and (max-device-width: 480px) {
 
  .cc-msg {
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 25px;
  }

}

@media only screen and (max-device-width: 320px) {
  
  .cc-msg {
    font-size: 0.85em;
    font-weight: 550;
    margin-bottom: 25px;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
 
}
`, "",{"version":3,"sources":["webpack://./src/account/finance/communities/PayUsingCC.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;;AAGA;;EAEE;IACE,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;AAEF;;AAEA;;EAEE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;AACF;;AAEA;;AAEA","sourcesContent":[".test-pay-button-place {\n  margin-top: -15px;\n}\n\n.some-backx {\n  padding-top: 5px;\n  padding-bottom: 5px;\n  font-size: 1em;\n  background-color: aliceblue;\n}\n\n.cc-msg {\n  font-size: 1em;\n  color: white;\n  font-weight: 650;\n  margin-bottom: 20px;\n  margin-top: 15px;\n}\n\n.platform-cc-payment-description {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: 10px;\n  color: #446d80;\n}\n\n\n@media only screen and (max-device-width: 480px) {\n \n  .cc-msg {\n    font-size: 0.9em;\n    font-weight: 600;\n    margin-bottom: 25px;\n  }\n\n}\n\n@media only screen and (max-device-width: 320px) {\n  \n  .cc-msg {\n    font-size: 0.85em;\n    font-weight: 550;\n    margin-bottom: 25px;\n  }\n}\n\n@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

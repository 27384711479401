// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cal-event-box {
  width: 100%;
  height: 690px;
  border: 2px solid gray;
  padding: 5px;
  margin-top: 5px;
  background-color: #d3e0e6;
  /* background-color: white; */
  box-shadow: 3px 3px 0px 0px black;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-top: 5px;
  /* margin-left: 5%; */
}

.create-event-lower-btn-pos {
  margin-bottom: 5px;
}

.cal-event-header {
  font-size: 1.2em;
  font-weight: 650;
  color: #0a4854;
}

hr.event-btn-divider {
  border-top: 3px double #8c8b8b;
  margin-top: -5px;
}

.event-ctrl-button {
  margin-left: -1px;
}

@media only screen and (max-device-width: 480px) {
}

@media only screen and (max-device-width: 320px) {
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/calendar/event/CreateEvent.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,6BAA6B;EAC7B,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".cal-event-box {\n  width: 100%;\n  height: 690px;\n  border: 2px solid gray;\n  padding: 5px;\n  margin-top: 5px;\n  background-color: #d3e0e6;\n  /* background-color: white; */\n  box-shadow: 3px 3px 0px 0px black;\n  overflow-y: auto;\n  overflow-x: hidden;\n  border-radius: 5px;\n  margin-bottom: 25px;\n  margin-top: 5px;\n  /* margin-left: 5%; */\n}\n\n.create-event-lower-btn-pos {\n  margin-bottom: 5px;\n}\n\n.cal-event-header {\n  font-size: 1.2em;\n  font-weight: 650;\n  color: #0a4854;\n}\n\nhr.event-btn-divider {\n  border-top: 3px double #8c8b8b;\n  margin-top: -5px;\n}\n\n.event-ctrl-button {\n  margin-left: -1px;\n}\n\n@media only screen and (max-device-width: 480px) {\n}\n\n@media only screen and (max-device-width: 320px) {\n}\n\n@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

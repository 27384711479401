// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-present-box {
  display: block;
  text-align: center;
  width: 100%;
  height: 640px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  margin-bottom: 25px;
}

.payment-list-scroll-box {
  display: block;
  text-align: center;
  width: 100%;
  height: 540px;
  border: 2px solid gray;
  /* padding: 5px; */
  background-color: #fff;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
}

.payment-list-header {
  font-size: 1.1em;
  font-weight: 650;
}

.pay-line-lbl {
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: -3px;
}

.pay-line-lbl-name {
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: -3px;
  padding-left: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/reports/mypayments/MyPaymentList.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".payment-present-box {\n  display: block;\n  text-align: center;\n  width: 100%;\n  height: 640px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 1%;\n  margin-bottom: 25px;\n}\n\n.payment-list-scroll-box {\n  display: block;\n  text-align: center;\n  width: 100%;\n  height: 540px;\n  border: 2px solid gray;\n  /* padding: 5px; */\n  background-color: #fff;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.payment-list-header {\n  font-size: 1.1em;\n  font-weight: 650;\n}\n\n.pay-line-lbl {\n  font-size: 0.9em;\n  font-weight: 600;\n  margin-top: 3px;\n  margin-bottom: -3px;\n}\n\n.pay-line-lbl-name {\n  font-size: 0.9em;\n  font-weight: 600;\n  margin-top: 3px;\n  margin-bottom: -3px;\n  padding-left: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

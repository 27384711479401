// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comp-intro-header {
  font-size: 1em;
  font-weight: 650;
}
.comp-intro-type-pos {
  margin-bottom: 10px;
}

.intro-comp-lbl {
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 15px;
}

.comp-intro-salute {
  width: 90%;
  font-size: 0.9em;
}

.intro_body_textarea {
  width: 100%;
  font-size: 0.9em;
}

.comp-intro-title {
  width: 100%;
  font-size: 0.9em;
}

.title-body-fm-btn1 {
  margin-left: -20px;
  margin-right: 20px;
}

.title-body-fm-btn2 {
  margin-left: -20px;
  margin-right: 20px;
  margin-top: 70px;
  margin-bottom: -70px;
}

.title-body-char-count {
  font-size: 0.8em;
  font-weight: 600;
  margin-top: -5px;
}

.comp-basic-intro-msg {
  font-size: 1em;
  font-weight: 650;
  margin-top: 15px;
}

.comp-basic-intro-msg-err {
  font-size: 1em;
  font-weight: 650;
  margin-top: 15px;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/templates/utils/CommonIntro.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".comp-intro-header {\n  font-size: 1em;\n  font-weight: 650;\n}\n.comp-intro-type-pos {\n  margin-bottom: 10px;\n}\n\n.intro-comp-lbl {\n  font-size: 0.9em;\n  font-weight: 600;\n  margin-bottom: 15px;\n}\n\n.comp-intro-salute {\n  width: 90%;\n  font-size: 0.9em;\n}\n\n.intro_body_textarea {\n  width: 100%;\n  font-size: 0.9em;\n}\n\n.comp-intro-title {\n  width: 100%;\n  font-size: 0.9em;\n}\n\n.title-body-fm-btn1 {\n  margin-left: -20px;\n  margin-right: 20px;\n}\n\n.title-body-fm-btn2 {\n  margin-left: -20px;\n  margin-right: 20px;\n  margin-top: 70px;\n  margin-bottom: -70px;\n}\n\n.title-body-char-count {\n  font-size: 0.8em;\n  font-weight: 600;\n  margin-top: -5px;\n}\n\n.comp-basic-intro-msg {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: 15px;\n}\n\n.comp-basic-intro-msg-err {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: 15px;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

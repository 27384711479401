// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-line {
  margin-top: 10px;
}

.summary-report-lbl {
  font-size: 1em;
  font-weight: 650;
}

.summary-report-text {
  font-size: 1em;
  font-weight: 650;
  color: #3d6680;
}

.summary-report-header-text {
  font-size: 1.1em;
  font-weight: 650;
}

.summary-report-header-row {
  font-size: 1em;
  font-weight: 650;
  margin-bottom: 10px;
}

.summary-report-time-row {
    font-size: 1em;
  font-weight: 650;
  margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/reports/finSummary/FinancialSummary.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;IACI,cAAc;EAChB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".report-line {\n  margin-top: 10px;\n}\n\n.summary-report-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.summary-report-text {\n  font-size: 1em;\n  font-weight: 650;\n  color: #3d6680;\n}\n\n.summary-report-header-text {\n  font-size: 1.1em;\n  font-weight: 650;\n}\n\n.summary-report-header-row {\n  font-size: 1em;\n  font-weight: 650;\n  margin-bottom: 10px;\n}\n\n.summary-report-time-row {\n    font-size: 1em;\n  font-weight: 650;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

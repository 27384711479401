// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swap-sub-cc-head {
    font-size: 1.2em;
    font-weight: 650;
    margin-bottom: 30px;
}

.swap-sub-cc-head-lbl{
    font-size: 1em;
    font-weight: 650;
    margin-bottom: 30px;
}

.btn-swap-cc-info {
    width: 40px;
    height: 30px;
    background-color: #325466;
    padding-top: 2px;
    border: none;
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
    box-shadow: 3px 3px 0px 0px black;
    margin-bottom: 5px;
    margin-left: 30px;
  }

  .btn-swap-cc-exit {
    width: 40px;
    height: 30px;
    background-color: #b05f3f;
    padding-top: 2px;
    border: none;
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
    box-shadow: 3px 3px 0px 0px black;
    margin-bottom: 5px;
  }
  

@media only screen and (max-device-width: 480px) {

.btn-swap-cc-info {
    width: 38px;
    height: 28px;
    background-color: #325466;
    padding-top: 2px;
    border: none;
    color: white;
    font-size: 0.8em;
    border-radius: 4px;
    box-shadow: 3px 3px 0px 0px black;
    margin-top: 10px;
    margin-bottom: 5px;
  }

}

`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/action/customer/swap/SwapSubscriptionCC.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,iCAAiC;IACjC,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,iCAAiC;IACjC,kBAAkB;EACpB;;;AAGF;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,iCAAiC;IACjC,gBAAgB;IAChB,kBAAkB;EACpB;;AAEF","sourcesContent":[".swap-sub-cc-head {\n    font-size: 1.2em;\n    font-weight: 650;\n    margin-bottom: 30px;\n}\n\n.swap-sub-cc-head-lbl{\n    font-size: 1em;\n    font-weight: 650;\n    margin-bottom: 30px;\n}\n\n.btn-swap-cc-info {\n    width: 40px;\n    height: 30px;\n    background-color: #325466;\n    padding-top: 2px;\n    border: none;\n    color: white;\n    font-size: 0.9em;\n    border-radius: 4px;\n    box-shadow: 3px 3px 0px 0px black;\n    margin-bottom: 5px;\n    margin-left: 30px;\n  }\n\n  .btn-swap-cc-exit {\n    width: 40px;\n    height: 30px;\n    background-color: #b05f3f;\n    padding-top: 2px;\n    border: none;\n    color: white;\n    font-size: 0.9em;\n    border-radius: 4px;\n    box-shadow: 3px 3px 0px 0px black;\n    margin-bottom: 5px;\n  }\n  \n\n@media only screen and (max-device-width: 480px) {\n\n.btn-swap-cc-info {\n    width: 38px;\n    height: 28px;\n    background-color: #325466;\n    padding-top: 2px;\n    border: none;\n    color: white;\n    font-size: 0.8em;\n    border-radius: 4px;\n    box-shadow: 3px 3px 0px 0px black;\n    margin-top: 10px;\n    margin-bottom: 5px;\n  }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  width: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button_line {
    width: 25px;
    height: 2px;
    background: white;
}
`, "",{"version":3,"sources":["webpack://./src/entrance/components/layout/Navbar/SideDrawer/DrawerToggleButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".toggle-button {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  height: 20px;\n  width: 25px;\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.toggle-button:focus {\n  outline: none;\n}\n\n.toggle-button_line {\n    width: 25px;\n    height: 2px;\n    background: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-contact-phone {
  width: 50%;
  margin-top: 15px;
  font-size: .9em;
  margin-left: 20%;
  margin-right: -20%;
  border: 1px solid black;
  margin-bottom: 10px;
}

.event-contact-lbl {
  font-size: 1em;
  font-weight: 600;
  margin-top: 10px;
}

.event-contact-text {
  font-size: .9em;
  width: 70%;
  margin-top: 10px;
}

.event-contact-phone {
  font-size: .9em;
  width: 40%;
  margin-top: 10px;
  margin-left: 1px;
}

.event-contact-state {
  font-size: .9em;
  width: 40%;
  margin-top: 10Px;
}

.event-contact-zip {
  font-size: .9em;
  width: 30%;
  margin-top: 10Px;
}

.contact-msg{
  font-size: 1em;
  font-weight: 600;
}
  
  @media only screen and (max-device-width: 480px) {

}

@media only screen and (max-device-width: 320px) {

}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/calendar/event/parts/Contact.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;EAEE;;AAEF;;AAEA;;AAEA;;AAEA;;AAEA","sourcesContent":[".event-contact-phone {\n  width: 50%;\n  margin-top: 15px;\n  font-size: .9em;\n  margin-left: 20%;\n  margin-right: -20%;\n  border: 1px solid black;\n  margin-bottom: 10px;\n}\n\n.event-contact-lbl {\n  font-size: 1em;\n  font-weight: 600;\n  margin-top: 10px;\n}\n\n.event-contact-text {\n  font-size: .9em;\n  width: 70%;\n  margin-top: 10px;\n}\n\n.event-contact-phone {\n  font-size: .9em;\n  width: 40%;\n  margin-top: 10px;\n  margin-left: 1px;\n}\n\n.event-contact-state {\n  font-size: .9em;\n  width: 40%;\n  margin-top: 10Px;\n}\n\n.event-contact-zip {\n  font-size: .9em;\n  width: 30%;\n  margin-top: 10Px;\n}\n\n.contact-msg{\n  font-size: 1em;\n  font-weight: 600;\n}\n  \n  @media only screen and (max-device-width: 480px) {\n\n}\n\n@media only screen and (max-device-width: 320px) {\n\n}\n\n@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

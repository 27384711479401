// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coop-store-closeopen-box {
  display: block;
  text-align: center;
  width: 100%;
  height: 380px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #fff;
  /* box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px; */
  /* position: absolute; */
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 1%; */
  /* margin-bottom: 25px; */
}

.coop-close-open-head {
  font-size: 1em;
  font-weight: 650;
  margin-bottom: 15px;
}

.co-op-closeopen-row {
  margin-bottom: 20px;
}

.coop-openclose-msg {
  font-size: 1em;
  font-weight: 650;
}

.coop-openclose-msg-err {
  font-size: 1em;
  font-weight: 650;
  color: red;
}

.coop-keep-openclose-row {
  margin-top: 10px;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/coop/CloseOpen.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB;yBACuB;EACvB,wBAAwB;EACxB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".coop-store-closeopen-box {\n  display: block;\n  text-align: center;\n  width: 100%;\n  height: 380px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #fff;\n  /* box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px; */\n  /* position: absolute; */\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  /* margin-top: 1%; */\n  /* margin-bottom: 25px; */\n}\n\n.coop-close-open-head {\n  font-size: 1em;\n  font-weight: 650;\n  margin-bottom: 15px;\n}\n\n.co-op-closeopen-row {\n  margin-bottom: 20px;\n}\n\n.coop-openclose-msg {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.coop-openclose-msg-err {\n  font-size: 1em;\n  font-weight: 650;\n  color: red;\n}\n\n.coop-keep-openclose-row {\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

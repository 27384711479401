// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storekeep-return-box {
    display: block;
    /* margin: auto; */
    text-align: center;
    width: 70%;
    height: 400px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    /* position: absolute; */
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 25px;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/coop/return/ReturnPolicySetup.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,8EAA8E;IAC9E,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB","sourcesContent":[".storekeep-return-box {\n    display: block;\n    /* margin: auto; */\n    text-align: center;\n    width: 70%;\n    height: 400px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #fff;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    /* position: absolute; */\n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 2%;\n    margin-bottom: 25px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

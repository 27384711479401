// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-game-setup-box {
    display: block;
    text-align: center;
    width: 85%;
    height: 610px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 25px;
  }

  .cat-teamgame-head {
    font-size: 1.1em;
    font-weight: 650;
    margin-bottom: 15px;
  }

  .mkt-game-msg-pos {
    margin-top: 20px;
  }

  .mkt-game-msg {
    padding: 20%;
    font-size: 1;
    font-weight: 600;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/catalog/teamGame/TeamGameSetup.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,8EAA8E;IAC9E,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":[".catalog-game-setup-box {\n    display: block;\n    text-align: center;\n    width: 85%;\n    height: 610px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #fff;\n    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n    border-radius: 5px;\n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 3%;\n    margin-bottom: 25px;\n  }\n\n  .cat-teamgame-head {\n    font-size: 1.1em;\n    font-weight: 650;\n    margin-bottom: 15px;\n  }\n\n  .mkt-game-msg-pos {\n    margin-top: 20px;\n  }\n\n  .mkt-game-msg {\n    padding: 20%;\n    font-size: 1;\n    font-weight: 600;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

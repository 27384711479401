// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.market-main-page-pos {
  margin-top: 5%;
}

.market-main-buttons-pos {
  margin-top: 5px;
}

.outsider-main-ctrl-button-pos {
  margin-top: 4.5%;
  margin-bottom: 0.5%;
}

.outsider-banner-pos {
  margin-top: 55px;
  margin-bottom: 5px;
}

.market-main-banner {
  height: 40px;
  width: 100%;
  max-width: 1200px;
  margin-top: -4px;
}

.market-main-banner-pos {
}

.a-landing-pov-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mkt-ban-pos-xx {
}

@media only screen and (max-device-width: 480px) {
  .market-main-page-pos {
    margin-top: 20%;
  }

  .outsider-main-ctrl-button-pos {
    /* margin-top: 7%;
    margin-bottom: 1%; */
  }

  .market-main-banner {
    height: 40px;
    width: 100%;
    max-width: 800px;
    margin-top: 0px;
  }
  .mkt-ban-pos-xx {
    margin-top: -7%;
  }

  .outsider-banner-pos {
    margin-top: 20%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/MarketMain.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;AACA;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;AACA;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE;wBACoB;EACtB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".market-main-page-pos {\n  margin-top: 5%;\n}\n\n.market-main-buttons-pos {\n  margin-top: 5px;\n}\n\n.outsider-main-ctrl-button-pos {\n  margin-top: 4.5%;\n  margin-bottom: 0.5%;\n}\n\n.outsider-banner-pos {\n  margin-top: 55px;\n  margin-bottom: 5px;\n}\n\n.market-main-banner {\n  height: 40px;\n  width: 100%;\n  max-width: 1200px;\n  margin-top: -4px;\n}\n\n.market-main-banner-pos {\n}\n\n.a-landing-pov-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.mkt-ban-pos-xx {\n}\n\n@media only screen and (max-device-width: 480px) {\n  .market-main-page-pos {\n    margin-top: 20%;\n  }\n\n  .outsider-main-ctrl-button-pos {\n    /* margin-top: 7%;\n    margin-bottom: 1%; */\n  }\n\n  .market-main-banner {\n    height: 40px;\n    width: 100%;\n    max-width: 800px;\n    margin-top: 0px;\n  }\n  .mkt-ban-pos-xx {\n    margin-top: -7%;\n  }\n\n  .outsider-banner-pos {\n    margin-top: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gptg-head {
  font-size: 1em;
  font-weight: 650;
}

.grp-target-name {
  width: 50%;
}

.grp-tg-name-pos {
  margin-top: 15px;
}

.group-to-tg-msg {
  margin-top: 15px;
  margin-bottom: 10px;
}

.grp-to-tg-msg {
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 600;
}

.grp-to-tg-msgerr {
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 600;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/buyTarget/group/GroupTarget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".gptg-head {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.grp-target-name {\n  width: 50%;\n}\n\n.grp-tg-name-pos {\n  margin-top: 15px;\n}\n\n.group-to-tg-msg {\n  margin-top: 15px;\n  margin-bottom: 10px;\n}\n\n.grp-to-tg-msg {\n  margin-top: 10px;\n  font-size: 0.9em;\n  font-weight: 600;\n}\n\n.grp-to-tg-msgerr {\n  margin-top: 10px;\n  font-size: 0.9em;\n  font-weight: 600;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

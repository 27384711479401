// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-ctrl-filter-box {
  display: block;
  text-align: center;
  width: 100%;
  height: 400px;
  border: 2px solid gray;
  padding: 5px;
  background-color: #e4e8f0;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 25px;
}

.btn-pay-ctrl-filter-exit {
  width: 50px;
  height: 30px;
  background-color: #b34505;
  border: none;
  color: white;
  font-size: 0.9em;
  font-weight: 550;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 5px;
}


.pay-ctrl-filter-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 40px;
}

.pay-ctrl-filter-email-pos {
  margin-top: 20px;
}

.pay-ctrl-filter-email-lbl {
  font-size: 1em;
  font-weight: 650;
}

.pay-ctrl-filter-email {
  width: 80%;
}

.pay-ctrl-filter-msg-err {
  font-size: 1em;
  font-weight: 650;
  color: red;
  margin-top: 35px;
}

.pay-ctrl-filter-msg {
  font-size: 1em;
  font-weight: 650;
  margin-top: 35px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/payctrl/PayCtrlFilter.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,8EAA8E;EAC9E,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;;AAGA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".payment-ctrl-filter-box {\n  display: block;\n  text-align: center;\n  width: 100%;\n  height: 400px;\n  border: 2px solid gray;\n  padding: 5px;\n  background-color: #e4e8f0;\n  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 5px;\n  z-index: 10;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 2%;\n  margin-bottom: 25px;\n}\n\n.btn-pay-ctrl-filter-exit {\n  width: 50px;\n  height: 30px;\n  background-color: #b34505;\n  border: none;\n  color: white;\n  font-size: 0.9em;\n  font-weight: 550;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-top: 10px;\n  margin-left: 15px;\n  margin-bottom: 5px;\n}\n\n\n.pay-ctrl-filter-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 40px;\n}\n\n.pay-ctrl-filter-email-pos {\n  margin-top: 20px;\n}\n\n.pay-ctrl-filter-email-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.pay-ctrl-filter-email {\n  width: 80%;\n}\n\n.pay-ctrl-filter-msg-err {\n  font-size: 1em;\n  font-weight: 650;\n  color: red;\n  margin-top: 35px;\n}\n\n.pay-ctrl-filter-msg {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

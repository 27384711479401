// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-body-event-upload {
  width: 80px;
  height: 30px;
  background-color: #0e8a77;
  border: none;
  color: white;
  font-size: 0.9em;
  font-size: 550;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 10px;
}

.btn-mms-event-upload {
  width: 120px;
  height: 30px;
  background-color: #7d4a10;
  border: none;
  color: white;
  font-size: 0.9em;
  font-size: 550;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-top: 10px;
}

.mms-row {
    margin-top: 15px;
}
.mms-radio{
    margin-top: 15px;
    margin-right: -25%;
}

.mms-image-video-col {
    margin-top: 7px;
    margin-bottom: -7px;
}

.event-attach-message{
  font-size: 1em;
  padding-left: 15%;
  padding-right: 15%;
  font-weight: 600;
  margin-top: 20px;
}

.upload-published-msg {
  margin-top: 50px;
  font-size: 1em;
  font-weight: 650;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/calendar/event/parts/Upload.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".btn-body-event-upload {\n  width: 80px;\n  height: 30px;\n  background-color: #0e8a77;\n  border: none;\n  color: white;\n  font-size: 0.9em;\n  font-size: 550;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-top: 15px;\n  margin-left: 10px;\n}\n\n.btn-mms-event-upload {\n  width: 120px;\n  height: 30px;\n  background-color: #7d4a10;\n  border: none;\n  color: white;\n  font-size: 0.9em;\n  font-size: 550;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-top: 10px;\n}\n\n.mms-row {\n    margin-top: 15px;\n}\n.mms-radio{\n    margin-top: 15px;\n    margin-right: -25%;\n}\n\n.mms-image-video-col {\n    margin-top: 7px;\n    margin-bottom: -7px;\n}\n\n.event-attach-message{\n  font-size: 1em;\n  padding-left: 15%;\n  padding-right: 15%;\n  font-weight: 600;\n  margin-top: 20px;\n}\n\n.upload-published-msg {\n  margin-top: 50px;\n  font-size: 1em;\n  font-weight: 650;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

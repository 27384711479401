// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-box {
  width: 80%;
  height: auto;
  border: 1px solid gray;
  padding: 15px;
  margin-top: 5%;
  background-color: #e4eff5;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  margin-left: 10%;
  margin-bottom: 10px;
}

.btn-notification-return {
  width: 80px;
  height: 30px;
  background-color: #386b82;
  border: none;
  color: white;
  font-size: 10pt;
  box-shadow: 3px 3px 0px 0px black;
  border-radius: 5px;
  /* margin-top: 3px; */
  margin-right: 5px;
}

.noti-head {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 15px;
}

.noti-message {
  font-size: 1em;
  font-weight: 600;
  padding: 20px;
  color: #2b5569;
  font-style: oblique;
  font-family: "Comic Sans MS", cursive, sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/converse/present/ShowNotification.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,yBAAyB;EACzB,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,iCAAiC;EACjC,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,iDAAiD;AACnD","sourcesContent":[".notification-box {\n  width: 80%;\n  height: auto;\n  border: 1px solid gray;\n  padding: 15px;\n  margin-top: 5%;\n  background-color: #e4eff5;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  margin-left: 10%;\n  margin-bottom: 10px;\n}\n\n.btn-notification-return {\n  width: 80px;\n  height: 30px;\n  background-color: #386b82;\n  border: none;\n  color: white;\n  font-size: 10pt;\n  box-shadow: 3px 3px 0px 0px black;\n  border-radius: 5px;\n  /* margin-top: 3px; */\n  margin-right: 5px;\n}\n\n.noti-head {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 15px;\n}\n\n.noti-message {\n  font-size: 1em;\n  font-weight: 600;\n  padding: 20px;\n  color: #2b5569;\n  font-style: oblique;\n  font-family: \"Comic Sans MS\", cursive, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

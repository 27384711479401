import React, { Component } from "react";

import axios from "axios";

import LoadFileToS3 from "../../../../../../../utils/components/fileUpload/LoadFileToS3";

import "./UploadCompositionDA.css";

const imageTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const videoTypes = ["mp4", "webm"];

const maxImageSize = 5242880; // 5MB
const maxVideoSize = 99928800; // 50mb

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastWip = "/routes/interact/saveBroadcastWip";

class UploadCompositionDA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      bannerS3FileData: null,
      imageS3FileData: null,
      s3FileDataDA: null,
      seFileData: null,

      broadcastSubDirName: "",

      typeSelectFlag: true,
      imageTypeFlag: false,

      openS3imageFlag: false,

      attachImageFlag: false,

      theBroadcast: null,
      broadcastId: 0,

      // daSize: "medium",

      daType: "image",

      showLoadS3Flag: false,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let attachVideoFlag = false;
    let attachImageFlag = false;
    let imageS3FileData = null;
    let s3FileData = null;

    let broadcastSubDirName = "broadcast-" + this.props.communityId.toString() + "-" + this.props.broadcastId.toString();

    // console.log("this.props.daType:", this.props.daType);
    if (this.props.daType === "image" || this.props.daType === "video") {
      attachImageFlag = true;
    }

    this.props.theBroadcast.mailComposition.fileUploads.forEach((elm) => {
      if (this.props.compositionComponent === "frames") {
        if (elm.compositionComponent === this.props.compositionComponent) {
          if (elm.seqNo === this.props.currSeqNo) {
            if (elm.location !== "") {
              imageS3FileData = elm;
              s3FileData = elm;
            }
          }
        }
      } else {
        if (elm.compositionComponent === this.props.compositionComponent) {
          imageS3FileData = elm;
          s3FileData = elm;
        }
      }
    });

    this.setState({
      attachImageFlag,
      attachVideoFlag,
      theBroadcast: this.state.theBroadcast,
      imageS3FileData,
      s3FileData,
      showLoadS3Flag: true,
      broadcastSubDirName
    });
  };

  returnToUploadCompositionDA = async (fileData) => {
    // console.log("returnToUploadCompositionDA fileData:", fileData);

    let bannerS3FileData = this.state.bannerS3FileData;
    let imageS3FileData = this.state.imageS3FileData;
    let imageLoaded = false;

    if (fileData.ops === "cancel") {
      this.setState({
        attachVideoFlag: false,
        attachImageFlag: false,
      });
    } else {
      // console.log("Not cancel ...");
      let s3FileData = null;
      if (fileData.s3FileData) {
        let currSeqNo = 0;
        if (this.props.compositionComponent === "frames") {
          currSeqNo = this.props.currSeqNo;
        }
        s3FileData = {
          key: fileData.s3FileData.key,
          type: this.props.daType,
          location: fileData.s3FileData.location,
          compositionComponent: this.props.compositionComponent,
          size: fileData.s3FileData.size,
          seqNo: currSeqNo,
          sliderBody: null,
        };
      }

      if (this.props.compositionComponent === "banner") {
        bannerS3FileData = fileData.s3FileData;
      }
      if (this.props.compositionComponent === "basicCompImage") {
        imageS3FileData = fileData.s3FileData;
      }

      if (this.props.compositionComponent === "newsLetterImage") {
        imageS3FileData = fileData.s3FileData;
      }

      if (this.props.compositionComponent === "frame") {
        imageS3FileData = fileData.s3FileData;
      }

      if (this.props.compositionComponent === "poster") {
        imageS3FileData = fileData.s3FileData;
      }

      if (this.props.compositionComponent === "video") {
        imageS3FileData = fileData.s3FileData;
      }

      let currSeqNo = 0;
      if (this.props.compositionComponent === "frames")
        currSeqNo = this.props.currSeqNo;

      let input = {
        ifDeleted: fileData.ifDeletedFlag,
        compType: this.props.compositionComponent,
        templateType: this.props.selectedTemplate,
        s3FileData,
        updateType: "DA",
        currSeqNo,
        broadcastId: this.props.broadcastId,
        communityId: this.props.communityId,
        clientProgram: "UploadCompositionDA",
        clientFunction: "returnToUploadCompositionDA",
      };

      // console.log("returnToUploadCompositionDA input:", input);

      if (s3FileData) imageLoaded = true;

      await this.uploadFileInDB(input);

      this.setState({
        attachImageFlag: false,
        bannerS3FileData,
        imageS3FileData,
      });
    }

    let retval = {
      imageLoaded,
      fileData
    }

    this.props.returnToCaller(retval);
    // this.props.returnToCaller(imageLoaded);
  };

  uploadFileInDB = async (input) => {
    let url = baandaServer + saveBroadcastWip;
    // console.log("### url:", url, " input:", input);
    try {
      let resp = await axios.post(url, input);
      // console.log("Upload resp:", resp);
      if (resp.data.status === "success") {
        this.setState({
          errMsg: "",
          errFlag: false,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('UploadCompositionDA...');

    let outputPanel;

    let s3FileDataDA = null;
    // if (this.props.selectedTemplate === "basic" && this.state.showLoadS3Flag) {
    if (this.state.showLoadS3Flag) {
      if (this.props.daType === "banner") {
        s3FileDataDA = this.state.bannerS3FileData;
      }
      if (this.props.daType === "image") {
        s3FileDataDA = this.state.imageS3FileData;
      }
      if (this.props.daType === "video") {
        s3FileDataDA = this.state.imageS3FileData;
      }

      if (this.state.attachImageFlag) {
        let fileTypeDA;
        let filemaxsize = 0;
        if (this.props.daType === "video") {
          fileTypeDA = videoTypes;
          filemaxsize = maxVideoSize;
        } else {
          fileTypeDA = imageTypes;
          filemaxsize = maxImageSize;
        }
        outputPanel = (
          <div>
            <LoadFileToS3
              returnToCaller={this.returnToUploadCompositionDA}
              communityId={this.props.communityId}
              fileType={fileTypeDA}
              maxSize={filemaxsize}
              s3subdir={this.state.broadcastSubDirName}
              requestor="Campaign"
              s3FileData={s3FileDataDA}
              type={this.state.daType}
            />
            <div
              className={
                this.state.errFlag
                  ? "text-center comp-da-upload-msg-err"
                  : "text-center comp-da-upload-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      }
    }

    return <div>{outputPanel}</div>;
  }
}

export default UploadCompositionDA;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-recvpay-lbl {
  font-size: 1em;
  font-weight: 650;
}

.store-recvpay-txt {
  font-size: 1em;
  font-weight: 650;
  color: #416f91;
}

.store-comp-tr-header {
  font-size: 1.1em;
  font-weight: 650;
}

.store-recpay-toppanel-pos {
  margin-top: 15px;
}

.store-payrecv-confirm {
  font-size: 1em;
  font-weight: 650;
  margin-top: 20px;
  margin-bottom: 20px;
}

.store-payrecv-amt {
    width: 20%;
}

.store-payrecv-note{
    width: 100%;
}

.payrecv-note-row-pos{
    margin-top: 15px;
    margin-bottom: 15px;
}

.coexchange-amt-pos {
    margin-top: 10px;
}

.coop-ex-fr-msg{
    font-size: .8em;
    margin-top: 10px;
    /* margin-left: -10px; */
}

.coexchange-chk-lines {
    margin-bottom: 10px;
}

.coex-check-pay-to {
    width: 80%;
}

.coex-check-bankname {
    width: 80%;
}

.coex-check-acno {
    width: 40%;
}

.coex-msg {
    font-size: 1em;
    font-weight: 600;
}

.coex-msg-err {
    font-size: 1em;
    font-weight: 600;
    color: red;
}

.store-recv-cc-pay-head {
    font-size: 1.1em;
    font-weight: 650;
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/finance/receivable/coopExchange/StoreRecvPay.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".store-recvpay-lbl {\n  font-size: 1em;\n  font-weight: 650;\n}\n\n.store-recvpay-txt {\n  font-size: 1em;\n  font-weight: 650;\n  color: #416f91;\n}\n\n.store-comp-tr-header {\n  font-size: 1.1em;\n  font-weight: 650;\n}\n\n.store-recpay-toppanel-pos {\n  margin-top: 15px;\n}\n\n.store-payrecv-confirm {\n  font-size: 1em;\n  font-weight: 650;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.store-payrecv-amt {\n    width: 20%;\n}\n\n.store-payrecv-note{\n    width: 100%;\n}\n\n.payrecv-note-row-pos{\n    margin-top: 15px;\n    margin-bottom: 15px;\n}\n\n.coexchange-amt-pos {\n    margin-top: 10px;\n}\n\n.coop-ex-fr-msg{\n    font-size: .8em;\n    margin-top: 10px;\n    /* margin-left: -10px; */\n}\n\n.coexchange-chk-lines {\n    margin-bottom: 10px;\n}\n\n.coex-check-pay-to {\n    width: 80%;\n}\n\n.coex-check-bankname {\n    width: 80%;\n}\n\n.coex-check-acno {\n    width: 40%;\n}\n\n.coex-msg {\n    font-size: 1em;\n    font-weight: 600;\n}\n\n.coex-msg-err {\n    font-size: 1em;\n    font-weight: 600;\n    color: red;\n}\n\n.store-recv-cc-pay-head {\n    font-size: 1.1em;\n    font-weight: 650;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

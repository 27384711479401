// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixedsize-brodcastlog-view {
    height: 450px;
    width: 100%;
    border: 1px solid gray;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .brd-intel-header {
    font-size: 1.1em;
    font-weight: 650;
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .brd-hdr-lbl-name {
    font-size: 1em;
    font-weight: 650;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/intel/ShowBroadcastIntel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB","sourcesContent":[".fixedsize-brodcastlog-view {\n    height: 450px;\n    width: 100%;\n    border: 1px solid gray;\n    background-color: white;\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n\n  .brd-intel-header {\n    font-size: 1.1em;\n    font-weight: 650;\n    margin-top: -5px;\n    margin-bottom: 5px;\n  }\n\n  .brd-hdr-lbl-name {\n    font-size: 1em;\n    font-weight: 650;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keeper-talk-log-box {
    display: block;
    text-align: center;
    width: 100%;
    height: 480px;
    border: 2px solid gray;
    padding: 5px;
    background-color: #fff;
    /* box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08); */
    border-radius: 5px;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 25px;
  }

  .coop-talklog-head{
    font-size: 1em;
    font-weight: 650;
  }

  .coop-talklog-talk {
    width: 100%
  }

  .coop-talklog-lbl {
    font-size: 1em;
    font-weight: 650;
  }

  .coop-talklog-talk-row {
    margin-top: 10px;
  }

  .coop-talklog-name {
    font-size: .9em;
    font-weight: 650;
    color: grey;
  }

  .coop-talklog-message {
    font-size: .9em;
    font-weight: 650;
    color: #3b4194;
    padding: 1%;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/coop/TalkLog.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,oFAAoF;IACpF,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE;EACF;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,WAAW;EACb","sourcesContent":[".keeper-talk-log-box {\n    display: block;\n    text-align: center;\n    width: 100%;\n    height: 480px;\n    border: 2px solid gray;\n    padding: 5px;\n    background-color: #fff;\n    /* box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5), 0 5px 15px 0 rgba(0, 0, 0, 0.08); */\n    border-radius: 5px;\n    z-index: 10;\n    overflow-y: auto;\n    overflow-x: hidden;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 2%;\n    margin-bottom: 25px;\n  }\n\n  .coop-talklog-head{\n    font-size: 1em;\n    font-weight: 650;\n  }\n\n  .coop-talklog-talk {\n    width: 100%\n  }\n\n  .coop-talklog-lbl {\n    font-size: 1em;\n    font-weight: 650;\n  }\n\n  .coop-talklog-talk-row {\n    margin-top: 10px;\n  }\n\n  .coop-talklog-name {\n    font-size: .9em;\n    font-weight: 650;\n    color: grey;\n  }\n\n  .coop-talklog-message {\n    font-size: .9em;\n    font-weight: 650;\n    color: #3b4194;\n    padding: 1%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inv-production-box {
    width: 100%;
    height: 600px;
    border: 1px solid gray;
    padding: 5px;
    margin-top: 5px;
    background-color: #f5f1d7;
    box-shadow: 3px 3px 0px 0px black;
    border-radius: 5px;
    margin-bottom: 5px;
    /* margin-left: 10%; */
    margin-top: 5px;
  }

  .btn-inv-prod-exit {
    width: 40px;
    height: 30px;
    background-color: #bf4808;
    border: none;
    color: white;
    font-size: 1em;
    font-weight: 650;
    box-shadow: 3px 3px 0px 0px black;
    border-radius: 5px;
    /* margin-bottom: 4px;
    margin-top: 10px; */
    margin-left: 5px;
  }
  
  
  .btn-modal-edu-production {
    width: 40px;
    height: 30px;
    background-color: #3c455e;
    border: none;
    color: white;
    font-size: 1em;
    box-shadow: 3px 3px 0px 0px black;
    border-radius: 5px;
    margin-left: 10px;
  }

  .inv-prod-header {
      font-size: 1.1em;
      font-weight: 650;
  }

  .digital-service-row-pos {
      margin-top: 10px;
  }

  .digital-srv-msg {
      font-size: 1em;
      font-weight: 600;
      color:darkslategray;
      padding-left: 10%;
      padding-right: 10%;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/store/inventory/digitalContent/Production.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,iCAAiC;IACjC,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,iCAAiC;IACjC,kBAAkB;IAClB;uBACmB;IACnB,gBAAgB;EAClB;;;EAGA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,iCAAiC;IACjC,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;MACI,gBAAgB;MAChB,gBAAgB;EACpB;;EAEA;MACI,gBAAgB;EACpB;;EAEA;MACI,cAAc;MACd,gBAAgB;MAChB,mBAAmB;MACnB,iBAAiB;MACjB,kBAAkB;EACtB","sourcesContent":[".inv-production-box {\n    width: 100%;\n    height: 600px;\n    border: 1px solid gray;\n    padding: 5px;\n    margin-top: 5px;\n    background-color: #f5f1d7;\n    box-shadow: 3px 3px 0px 0px black;\n    border-radius: 5px;\n    margin-bottom: 5px;\n    /* margin-left: 10%; */\n    margin-top: 5px;\n  }\n\n  .btn-inv-prod-exit {\n    width: 40px;\n    height: 30px;\n    background-color: #bf4808;\n    border: none;\n    color: white;\n    font-size: 1em;\n    font-weight: 650;\n    box-shadow: 3px 3px 0px 0px black;\n    border-radius: 5px;\n    /* margin-bottom: 4px;\n    margin-top: 10px; */\n    margin-left: 5px;\n  }\n  \n  \n  .btn-modal-edu-production {\n    width: 40px;\n    height: 30px;\n    background-color: #3c455e;\n    border: none;\n    color: white;\n    font-size: 1em;\n    box-shadow: 3px 3px 0px 0px black;\n    border-radius: 5px;\n    margin-left: 10px;\n  }\n\n  .inv-prod-header {\n      font-size: 1.1em;\n      font-weight: 650;\n  }\n\n  .digital-service-row-pos {\n      margin-top: 10px;\n  }\n\n  .digital-srv-msg {\n      font-size: 1em;\n      font-weight: 600;\n      color:darkslategray;\n      padding-left: 10%;\n      padding-right: 10%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vedio-test-send-header {
  font-size: 1.1em;
  font-weight: 650;
  margin-bottom: 25px;
  margin-top: 10px;
}

.vedio-ts-name {
  width: 70%;
}

.vedio-ts-lbl {
  font-size: 1em;
  font-weight: 650;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/compose/video/review/VideoTestSend.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".vedio-test-send-header {\n  font-size: 1.1em;\n  font-weight: 650;\n  margin-bottom: 25px;\n  margin-top: 10px;\n}\n\n.vedio-ts-name {\n  width: 70%;\n}\n\n.vedio-ts-lbl {\n  font-size: 1em;\n  font-weight: 650;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

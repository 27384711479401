// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cat-show-review-box {
    width: 100%;
    height: 700px;
    border: 1px solid gray;
    padding: 5px;
    margin-top: 5px;
    background-color: #f7faff;
    box-shadow: 3px 3px 0px 0px black;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .fixedsize-review-show {
    height: 380px;
    width: 100%;
    background-color: aliceblue;
    padding-right: 4%;
    white-space: pre-line;
    overflow-x: hidden;
    overflow-y: auto;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/market/catalog/reviews/Review.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,iCAAiC;IACjC,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,2BAA2B;IAC3B,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".cat-show-review-box {\n    width: 100%;\n    height: 700px;\n    border: 1px solid gray;\n    padding: 5px;\n    margin-top: 5px;\n    background-color: #f7faff;\n    box-shadow: 3px 3px 0px 0px black;\n    border-radius: 5px;\n    margin-bottom: 5px;\n    margin-top: 5px;\n  }\n\n  .fixedsize-review-show {\n    height: 380px;\n    width: 100%;\n    background-color: aliceblue;\n    padding-right: 4%;\n    white-space: pre-line;\n    overflow-x: hidden;\n    overflow-y: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.brd-trg-merge-box {
    width: 60%;
    height: 300px;
    border: 1px solid gray;
    margin-top: 5px;
    background-color: white;
    border-radius: 5px;
    border: solid 1px black;
    margin: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

.brd-merge-top-pos {
    margin-top: 10px;
}

.merge-tgt-new-name{
    font-size: .9em;
    width: 60%;
    margin-top: 4px;
    margin-bottom: 15px;
}

.merge-tgt-sel-drop-pos {
    margin-bottom: 20px;
}

.brd-list-merge-hight {
    margin-top: 2px;
    margin-bottom: 4px;
    height: 32px;
  }

  .row-merge-list-dr {
 
    background-color: #7d98ad;
    color: white;
    margin-bottom: 5px;
  }

  .row-merge-list-lt {
 
    background-color: white;
    color: #5c6f7d;
    margin-bottom: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/dashboard/components/connect/broadcast/parts/buyTarget/merge/MergeTargets.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,uBAAuB;IACvB,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;EAClB;;AAEF;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,UAAU;IACV,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,YAAY;EACd;;EAEA;;IAEE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;;IAEE,uBAAuB;IACvB,cAAc;IACd,kBAAkB;EACpB","sourcesContent":["\n.brd-trg-merge-box {\n    width: 60%;\n    height: 300px;\n    border: 1px solid gray;\n    margin-top: 5px;\n    background-color: white;\n    border-radius: 5px;\n    border: solid 1px black;\n    margin: auto;\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n\n.brd-merge-top-pos {\n    margin-top: 10px;\n}\n\n.merge-tgt-new-name{\n    font-size: .9em;\n    width: 60%;\n    margin-top: 4px;\n    margin-bottom: 15px;\n}\n\n.merge-tgt-sel-drop-pos {\n    margin-bottom: 20px;\n}\n\n.brd-list-merge-hight {\n    margin-top: 2px;\n    margin-bottom: 4px;\n    height: 32px;\n  }\n\n  .row-merge-list-dr {\n \n    background-color: #7d98ad;\n    color: white;\n    margin-bottom: 5px;\n  }\n\n  .row-merge-list-lt {\n \n    background-color: white;\n    color: #5c6f7d;\n    margin-bottom: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
